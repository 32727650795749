import React from 'react';
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';
import { Link } from 'react-router-dom'

const staticValue = [
    {   
        "id": "1",
        "key": "Balance in Bank",
        "name": "balance_in_bank",
        "description": "Cash in the bank. Known as BIB"
    },
    {
        "id": "2",
        "key": "Debtors",
        "name": "debtors",
        "description": "Customers who owe you money."
    },
    {
        "id": "3",
        "key": "Creditors",
        "name": "creditors",
        "description": "Suppliers you owe money to."
    },
    {
        "id": "4",
        "key": "Work In Progress",
        "name": "work_in_progress",
        "description": "Value of work you have agreed to do which has not yet been delivered"
    },
    {
        "id": "5",
        "key": "Quotes $ value for the week",
        "name": "quotes_value_for_the_week",
        "description": "The value of the quotes you sent out this week."
    },
    {   
        "id": "6",
        "key": "Cost of Goods Sold for the week",
        "name": "cost_of_goods_sold_for_the_week",
        "description": "What are the variable costs when you do a sale, e.g. sales staff. Known as COGS"
    },
    {
        "id": "7",
        "key": "Total Employment Costs for the week",
        "name": "total_employment_costs_for_the_week",
        "description": "The total costs of employing people this week. All inclusive."
    },
    {
        "id": "8",
        "key": "Revenue for the week",
        "name": "revenue_for_the_week",
        "description": "The amount that was invoiced/sold this week?"
    },
    {
        "id": "9",
        "key": "Number of invoices for the week",
        "name": "number_of_invoices_for_the_week",
        "description": "How many invoices/receipts for the week"
    },
    {
        "id": "10",
        "key": "Average $ Invoice for the week",
        "target": "Auto calculated",
        "name": "average_invoice_for_the_week",
        "description": "Revenue for week (actual) / Number Invoices (actual)"
    },
    {   
        "id": "11",
        "key": "Quotes $ value for the month",
        "target": "Auto calculated",
        "name": "quotes_value_for_the_month",
        "description": "Cash in the bank. Known as BIB"
    },
    {
        "id": "12",
        "key": "Cost of Goods Sold month to date",
        "target": "Auto calculated",
        "name": "cost_of_goods_sold_month_to_date",
        "description": "Calc: Sum of all of the 6. COGS for the week in this month."
    },
    {
        "id": "13",
        "key": "Revenue month to date",
        "target": "Auto calculated",
        "name": "revenue_month_to_date",
        "description": "Sum of all of the sales for the week in this month."
    },
    {
        "id": "14",
        "key": "Gross Profit % for the month to date",
        "name": "gross_profit_for_the_month_to_date",
        "description": "Actual COGS (month to date) / Actual sales for month"
    },
    {
        "id": "15",
        "key": "Utilization % average for the week.",
        "name": "utilization_average_for_the_week",
        "description": "Actual sales for week / Target Utilisation as a %"
    },
    {   
        "id": "16",
        "key": "Break Even Number/Monthly.*",
        "name": "break_even_number_or_monthly",
        "description": ""
    },
    {
        "id": "17",
        "key": "Total Employment Costs month to date",
        "name": "total_employment_costs_month_to_date",
        "description": "This your staff costs for the month to date. Enter your monthly staff costs."
    },
    {
        "id": "18",
        "key": "Total Employment Costs vs. Revenue ratio",
        "name": "total_employment_costs_vs_revenue_ratio",
        "description": "Actual total employment costs / Actual sales for month"
    },
    {
        "id": "19",
        "key": "Liquidity % Ratio",
        "name": "liquidity_ratio",
        "description": "(Actual BIB + Actual Debtors) / Actual Creditors."
    },
    {
        "id": "20",
        "key": "Actual Banked/Cash In for the week",
        "name": "actual_banked_or_cash_in_for_the_week",
        "description": "How much you banked as cash this week"
    },
    {   
        "id": "21",
        "key": "Actual Payouts/Cash Out for the week",
        "name": "actual_payouts_or_cash_out_for_the_week",
        "description": "How much you paid out in cash this week."
    },
    {
        "id": "22",
        "key": "TAX obligations up to date? Yes or No",
        "name": "tax_obligations_up_to_date_yes_or_no",
        "description": "Confirmation that our tax payments are paid or are you in a payment plan?"
    },
    {
        "id": "23",
        "key": "Credit Limit",
        "name": "credit_limit",
        "description": ""
    }
] 

class targets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            keynumbers: staticValue,
        }
    }

    saveKeyNumbers = async (e) => {
        e.preventDefault()
        console.log('localstorage---->',this.state)

        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                refresh: this.state.token
             })
        };
        await fetch('${process.env.REACT_APP_BACKEND_URL}/logout', requestOptions)
            .then(response => response.json())
            .then(data => {
                //  this.setState({ postId: data.id })
                 console.log('logout data->',data)
            });
         
    }

    render() {
        return (
            <React.Fragment>
                <div className="for_innerpages">
                <div className="wrapper">
               {/* <!-- Sidebar  --> */}
               <Sidebar></Sidebar>
        
                {/* <!-- Page Content  --> */}
                <div id="content" className="layoutset">
        
                    <div className="content_board_main_right">
                        <div className="page_header_set">
                            {/* <!-- Header  --> */}
                            <Header></Header>
                            <div className="page_breadcrumbs">
                                <h3 className="title">Home</h3>
                                <div className="breadcrumbs_link">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                          <g id="home" transform="translate(1 1)">
                                            <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>
                                    </div>
                                    <span><Link to='/dashboard'>Home</Link></span><span>-</span><span>Targets</span>
                                </div>
                            </div>
                        </div>
        
                        <div className="section_page_content">
                            <div className="tab_section">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data" type="button" role="tab" aria-controls="data" aria-selected="true">Data</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="chart-tab" data-bs-toggle="tab" data-bs-target="#chart" type="button" role="tab" aria-controls="chart" aria-selected="false">Charts & Export</button>
                                  </li>
                                  
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                        <div className="tab_filter">
                                            <h3>View Key Number Targets</h3>
                                            <div className="actoin_sec">
                                                <label>Select Week end Date:</label>
                                                <select id="select_weekend" className="select_weekend">
                                                    <option>Oct 17, 2021</option>
                                                    <option>Oct 18, 2021</option>
                                                    <option>Oct 19, 2021</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="theme_table">
                                            <table className="table dt-responsive nowrap widh100" id="data_table1">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>KEYNUMBERS</th>
                                                        <th className="text-center">TARGET</th>
                                                        <th>DESCRIPTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.keynumbers.map((prod,i) => {
                                                        return (
                                                            <tr>
                                                                <td class="bold_val">{prod.id}</td>
                                                                <td class="bold_val">{prod.key}</td>
                                                                { prod.target ? <td class="text-center"><div class="amount_box out_dollar">{prod.target}</div></td> :<td class="text-center"><div class="amount_box out_dollar"><span>$</span><input type="number"></input></div></td>}
                                                                <td>{prod.description}</td> 
                                                            </tr>
                                                        )
                                                   })
                                               }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="chart" role="tabpanel" aria-labelledby="chart-tab">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
        
                    </div>
        
                    <footer>
                        <div className="container">
                            <div className="footer_inner">
                                <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/metro-facebook.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-twitter.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-youtube.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-linkedin.svg"></img>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </footer>
                </div>
        
            </div>
                </div>
            </React.Fragment>
          )
    }
}
export default targets;
