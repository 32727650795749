import './App.css';
import RouterPage from './containers/router'

function App() {
  return (
    <div className="App">
        <RouterPage/>
    </div>
  );
}

export default App;
