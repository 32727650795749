import React, { Component, Fragment} from 'react'
import '../../App.css';
import './generic-calc.css';
import { Link } from 'react-router-dom'
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';


class generic extends Component{
    constructor(props){
      super(props);
  
      this.state = {
        currentCalc: 'deptRatio',
        deptRatio: '',
        assetsAmount: '',
        liabilitiesAmount: '',
        inputSales: '',
        inputOtherIncome: '',
        inputTotalLiabilities: '',
        debtToIncomeRatio: '',
        interestCover: '',
        salesAmount: '',
        netProfitBeforeTax: '',
        netProfitMargin: '',
        grossSalesAmount: '',
        grossProfitAmount: '',
        grossProfitMargin: '',
        netProfitBeforeTaxAssets: '',
        totalAssets: '',
        returnOnAssets: '',
        sales: '',
        costOfGoods: '',
        inputDeprecation: '',
        inputInterestExpense: '',
        inputLeaseExpense: '',
        totalExpenses: '',
        operatingExpensesToSales: '',
        inputSalesPerEmp: '',
        inputAvgNumberFTEmp: '',
        inputAvgNumberPTEmp: '',
        inputAvgWeeklyHours: '',
        numberOfOwners: '',
        salesPerEmployee: '',
        numberOfFullTime: '',
        inputCurrentAssetsAmount: '',
        inputCurrentLiabltiesAmount: '',
        workingCapital: '',
        inputStock: '',
        inputCurrentAssets: '',
        inputCurrentLiablties: '',
        quickAssetsRatio: '',
        costOfGoodsSold: '',
        inputOpeningStocks: '',
        inputClosingStocks: '',
        stockTurnOver: '',
        inputTradeDebtors: '',
        inputSalesAging: '',
        debtorsAgingRation: '',
        inputTradeCreditors: '',
        inputPurchaseAmount: '',
        creditorsAgingRation: ''
      }
    }

    resetValue = () => {
        this.setState({
            deptRatio: '',
            assetsAmount: '',
            liabilitiesAmount: '',
            inputSales: '',
            inputOtherIncome: '',
            inputTotalLiabilities: '',
            debtToIncomeRatio: '',
            interestCover: '',
            salesAmount: '',
            netProfitBeforeTax: '',
            netProfitMargin: '',
            grossSalesAmount: '',
            grossProfitAmount: '',
            grossProfitMargin: '',
            netProfitBeforeTaxAssets: '',
            totalAssets: '',
            returnOnAssets: '',
            sales: '',
            costOfGoods: '',
            inputDeprecation: '',
            inputInterestExpense: '',
            inputLeaseExpense: '',
            totalExpenses: '',
            operatingExpensesToSales: '',
            inputSalesPerEmp: '',
            inputAvgNumberFTEmp: '',
            inputAvgNumberPTEmp: '',
            inputAvgWeeklyHours: '',
            numberOfOwners: '',
            salesPerEmployee: '',
            numberOfFullTime: '',
            inputCurrentAssetsAmount: '',
            inputCurrentLiabltiesAmount: '',
            workingCapital: '',
            inputStock: '',
            inputCurrentAssets: '',
            inputCurrentLiablties: '',
            quickAssetsRatio: '',
            costOfGoodsSold: '',
            inputOpeningStocks: '',
            inputClosingStocks: '',
            stockTurnOver: '',
            inputTradeDebtors: '',
            inputSalesAging: '',
            debtorsAgingRation: '',
            inputTradeCreditors: '',
            inputPurchaseAmount: '',
            creditorsAgingRation: ''

        })
    }

    round = (num, decimalPlaces = 0) => {
        num = Math.round(num + "e" + decimalPlaces);
        return Number(num + "e" + -decimalPlaces);
    }

    setValue = (name, value) => {
        console.log('parseInt(value)-->',name,value,parseInt(value))
        this.setState({
            [name]: parseInt(value)
        })
    }

    calDeptRatio = () => {
        const {assetsAmount, liabilitiesAmount} = this.state

        var ratio;
        ratio = (liabilitiesAmount/assetsAmount)*100

        this.setState({
            deptRatio: this.round(ratio, 2) + '%'
        })
    }

    calDeptToIncomeRation = () => {
        const {inputSales, inputOtherIncome, inputTotalLiabilities} = this.state

        var totalIncome, divideValue, ratio
        totalIncome = inputSales + inputOtherIncome

        divideValue = (inputTotalLiabilities/totalIncome)
       
        ratio = this.round(divideValue,2) + ': 1'

        this.setState({
            debtToIncomeRatio: ratio
        })
    }

    calInterestCover = () => {

    }

    calNetProfitMargin = () => {
        const {salesAmount, netProfitBeforeTax} = this.state

        var ratio;
        ratio = (netProfitBeforeTax/salesAmount)*100

        this.setState({
            netProfitMargin: this.round(ratio, 2)+ '%'
        })
    }

    calGrossProfitMargin = () => {
        const {grossProfitAmount, grossSalesAmount} = this.state

        var ratio;
        ratio = (grossProfitAmount/grossSalesAmount)*100

        this.setState({
            grossProfitMargin: this.round(ratio, 2) + '%'
        })
    }

    calReturnOnAssets = () => {
        const {netProfitBeforeTaxAssets, totalAssets} = this.state

        var ratio;
        ratio = (netProfitBeforeTaxAssets/totalAssets)*100

        this.setState({
            returnOnAssets: this.round(ratio, 2) + '%'
        })
    }

    calExpensesToSales = () => {
        const {sales, costOfGoods, totalExpenses, inputDeprecation, inputLeaseExpense, inputInterestExpense} = this.state

        var add, minus, numerator, result
        add = costOfGoods + totalExpenses

        minus = inputDeprecation + inputLeaseExpense + inputInterestExpense

        numerator = add - minus

        result = (numerator/sales)*100
        
        this.setState({
            operatingExpensesToSales: this.round(result, 2) + '%'
        })
    }

    calSalesPerEmp = () => {
        const {inputSalesPerEmp, inputAvgNumberFTEmp, inputAvgNumberPTEmp, inputAvgWeeklyHours, numberOfOwners} = this.state

        var noOfEmployee, ratio
        // noOfEmployee = inputSalesPerEmp + inputAvgNumberFTEmp + inputAvgNumberPTEmp + inputAvgWeeklyHours

        var total_full_time = (inputAvgNumberFTEmp+numberOfOwners)+((inputAvgNumberPTEmp*inputAvgWeeklyHours)/40)

        var result = (inputSalesPerEmp/total_full_time)

        console.log('two values----->',total_full_time,result)

        // result = "{:.2f}".format(result)

        // total_full_time = "{:.2f}".format(total_full_time)




        // ratio = inputSalesPerEmp/noOfEmployee

        this.setState({
            salesPerEmployee: '$'+this.round(result, 2),
            numberOfFullTime: this.round(total_full_time,2 )
        })
    
    }

    calWorkingCapital = () => {
        const {inputCurrentAssetsAmount, inputCurrentLiabltiesAmount} = this.state

        var ratio = inputCurrentAssetsAmount/inputCurrentLiabltiesAmount

        this.setState({
            workingCapital: this.round(ratio, 2) + ':1'
        })


    }

    calQuickAssetRatio = () => {
        const {inputStock, inputCurrentAssets, inputCurrentLiablties} = this.state

        var numerator, ratio
        numerator = inputCurrentAssets - inputStock

        ratio = numerator/inputCurrentLiablties

        this.setState({
            quickAssetsRatio: this.round(ratio, 2)+ ':1 %'
        })
    }

    calStockTurnover = () => {
        const {costOfGoodsSold, inputOpeningStocks, inputClosingStocks } = this.state

        var avgStocks, ratio
        avgStocks = (inputOpeningStocks + inputClosingStocks)/2

        ratio = costOfGoodsSold/avgStocks

        this.setState({
            stockTurnOver: this.round(ratio, 2) + ' times'
        })
    }

    calDebtorsAgingRation = () => {
        const {inputTradeDebtors, inputSalesAging} = this.state
         
        var ratio
        ratio = (inputTradeDebtors/inputSalesAging)*365

        this.setState({
            debtorsAgingRation: this.round(ratio, 2) + ' days'
        })
    }

    calCreditorsAgingRation = () => {
        const {inputTradeCreditors, inputPurchaseAmount} = this.state
         
        var ratio
        ratio = (inputTradeCreditors/inputPurchaseAmount)*365

        this.setState({
            creditorsAgingRation: this.round(ratio, 2) + ' days'
        })
    }

    setCalc = async (name) => {
        this.setState({
            currentCalc: name
        })
        await this.resetValue()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.currentCalc !== this.state.currentCalc){
            document.getElementById("calculators-debt-ratio-content-id").scrollTo(0,0)
        }
    }
    
  render() {
    const {currentCalc, deptRatio, assetsAmount, liabilitiesAmount, inputSales, inputOtherIncome, inputTotalLiabilities, debtToIncomeRatio, interestCover, salesAmount, netProfitBeforeTax, netProfitMargin, grossSalesAmount, grossProfitAmount, grossProfitMargin, netProfitBeforeTaxAssets, totalAssets, returnOnAssets, sales, costOfGoods, totalExpenses, inputDeprecation, inputLeaseExpense, inputInterestExpense, operatingExpensesToSales,   inputSalesPerEmp,
    inputAvgNumberFTEmp,
    inputAvgNumberPTEmp,
    inputAvgWeeklyHours,
    numberOfOwners,
    salesPerEmployee,
    numberOfFullTime,
    inputCurrentAssetsAmount,
    inputCurrentLiabltiesAmount,
    workingCapital,
    inputStock,
    inputCurrentAssets,
    inputCurrentLiablties,
    quickAssetsRatio,
    costOfGoodsSold,
    inputOpeningStocks,
    inputClosingStocks,
    stockTurnOver,
    inputTradeDebtors,
    inputSalesAging,
    debtorsAgingRation,
    inputTradeCreditors,
    inputPurchaseAmount,
    creditorsAgingRation} = this.state
    return (
        <Fragment>
        <div className="wrapper">
        {/* <!-- Sidebar  --> */}
        <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>

             {/* <!-- Page Content  --> */}
          <div id="content" className="layoutset">
     
            <div className="content_board_main_right">
                <div className="page_header_set">
                    {/* <!-- Header  --> */}
                    <Header></Header>
                    <div className="page_breadcrumbs">
                        <h3 className="title">Home</h3>
                        <div className="breadcrumbs_link">
                            <div className="img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                <g id="home" transform="translate(1 1)">
                                    <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                                </svg>
                            </div>
                            <span><Link to='/dashboard'>Home</Link></span><span>-</span><span><Link to='/calculators'>Calculators</Link></span><span>-</span><span>generic</span>
                        </div>
                    </div>
                </div>
                <div class="calculators-debt-ratio-wrapper">
                    <div class="calculator-generic-section">
                        <div className="calculators-debt-ratio-listing">
                            <ul>
                                <li><a class={`${currentCalc == 'deptRatio' ? 'gold' : ''}`} name="deptRatio" href="#deptRatio" onClick={(e) => {e.preventDefault(); this.setCalc('deptRatio')}}>Debt Ratio</a></li>
                                <li><a class={`${currentCalc == 'deptToIncomeRatio' ? 'gold' : ''}`}  name="deptToIncomeRatio" href="#deptToIncomeRatio" onClick={(e) => {e.preventDefault(); this.setCalc('deptToIncomeRatio')}}>Debt to income ratio</a></li>
                                {/* <li><a name="interestCover" href="#interestCover" onClick={() => this.setCalc('interestCover')}>Interest Cover</a></li> */}
                                <li><a class={`${currentCalc == 'netProfitMargin' ? 'gold' : ''}`} name="netProfitMargin" href="#netProfitMargin" onClick={(e) => {e.preventDefault(); this.setCalc('netProfitMargin')}}>Net Profit Margin</a></li>
                                <li><a class={`${currentCalc == 'grossProfitMargin' ? 'gold' : ''}`} name="grossProfitMargin" href="#grossProfitMargin" onClick={(e) => {e.preventDefault(); this.setCalc('grossProfitMargin')}}>Gross Profit Margin</a></li>
                                <li><a class={`${currentCalc == 'returnOnAssets' ? 'gold' : ''}`} name="returnOnAssets" href="#returnOnAssets" onClick={(e) => {e.preventDefault(); this.setCalc('returnOnAssets')}}>Return on Assets</a></li>
                                <li><a class={`${currentCalc == 'expensesToSales' ? 'gold' : ''}`} name="expensesToSales" href="#expensesToSales" onClick={(e) => {e.preventDefault(); this.setCalc('expensesToSales')}}>Operating Expenses to Sales</a></li>
                                <li><a class={`${currentCalc == 'salesPerEmployee' ? 'gold' : ''}`} name="salesPerEmployee" href="#salesPerEmployee" onClick={(e) => {e.preventDefault(); this.setCalc('salesPerEmployee')}}>Sales per employee</a></li>
                                <li><a class={`${currentCalc == 'workingCapital' ? 'gold' : ''}`} name="workingCapital" href="#workingCapital" onClick={(e) => {e.preventDefault(); this.setCalc('workingCapital')}}>Working Capital</a></li>
                                <li><a class={`${currentCalc == 'quickAssetsRatio' ? 'gold' : ''}`} name="quickAssetsRatio" href="#quickAssetsRatio" onClick={(e) => {e.preventDefault(); this.setCalc('quickAssetsRatio')}}>Quick Assets Ratio</a></li>
                                <li><a class={`${currentCalc == 'stockTurnover' ? 'gold' : ''}`} name="stockTurnover" href="#stockTurnover" onClick={(e) => {e.preventDefault(); this.setCalc('stockTurnover')}}>Stock Turnover</a></li>
                                <li><a class={`${currentCalc == 'debtorAgeingRatio' ? 'gold' : ''}`} name="debtorAgeingRatio" href="#debtorAgeingRatio" onClick={(e) => {e.preventDefault(); this.setCalc('debtorAgeingRatio')}}>Debtor ageing ratio</a></li>
                                <li><a class={`${currentCalc == 'credtorAgeingRation' ? 'gold' : ''}`} name="credtorAgeingRation" href="#credtorAgeingRation" onClick={(e) => {e.preventDefault(); this.setCalc('credtorAgeingRation')}}>Creditor ageing ratio</a></li>
                            </ul>
                        </div>

                        <div className="calculators-debt-ratio-content" id='calculators-debt-ratio-content-id'>
                            {/* debt ratio */}
                            {currentCalc == 'deptRatio' ?
                            <div class="debt-ratio-calc calc">
                                <h3>Debt Ratio</h3>

                                <p>The debt ratio gives an indication of the gearing level of your business.</p>
                                <p>To gauge your business' overall position you may need to combine the input figures if there
                                    is more than one entity, for example if the assets are held in a different entity to the trading entity.</p>
                                <p>Use information from your business' annual balance sheet to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input total assets amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={assetsAmount} onChange={(e) => this.setValue('assetsAmount', e.target.value)} name="assetsAmount"/></div>
                                </div>
                                
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input total liabilities amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={liabilitiesAmount} onChange={(e) => this.setValue('liabilitiesAmount', e.target.value)} name="liabilitiesAmount"/></div>
                                </div>

                                {/* <table id="" class="table table-bordered table-striped">
                                    <thead>
                                    <tr class="table-light">
                                        <th>Input total assets amount:</th>
                                        <th> <div>$<input autocomplete="off" class="form-control calc-input" type="number" value={assetsAmount} onChange={(e) => this.setValue('assetsAmount', e.target.value)} name="assetsAmount"/> &nbsp; <span class="text-danger">*</span></div></th>
                                    </tr>
                                    <tr class="table-light">
                                        <th>Input total liabilities amount:</th>
                                        <th> $ <input type="number" value="200" class="form-control calc-input" step="any" name="b" required=""/> &nbsp; <span class="text-danger">*</span></th>
                                    </tr>
                                    </thead>
                                </table> */}

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {deptRatio !== '' ? 
                                        <div class="debt-ratio-result calc-result bold">Debt ratio : {deptRatio}</div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="debt-ratio-btn btn btn-primary" onClick={() => this.calDeptRatio()}>Calculate</button>
                                    </div>
                                </div>

                                <br />

                                <p>The debt ratio gives an indication of
                                    the level of debt to equity. The right level of debt for a business
                                    depends on many factors. Some advantages of higher debt levels are:
                                </p>
                                    <ul class="text1">
                                        <li class="text1">The deductibility of interest from business
                                        expenses can provide tax advantages.</li>
                                        <li class="text1">Returns on equity can be higher.</li>
                                        <li class="text1">Debt can provide a suitable source of capital
                                        to start or expand a business.</li>
                                    </ul>

                                <p class="text1">Some disadvantages can be:</p>
                                    <ul class="text1">
                                        <li>Sufficient cash flow is required to service a higher
                                        debt load. The need for this cash flow can place pressure
                                        on a business if income streams are erratic.
                                        </li>
                                        <li>Susceptibility to interest rate increases.</li>
                                        <li>Channelling cash flow to service debt may starve
                                        expenditure in other areas such as development which
                                        can be detrimental to overall survival of the business.
                                        </li>
                                    </ul>

                                <p class="text1">The calculation used to obtain the ratio is:</p>
                                <div class="bold">Debt Ratio = (Total liabilities/Total assets) * 100</div>
                            </div>: ''}

                            {/* debt to income */}
                            {currentCalc == 'deptToIncomeRatio' ?
                            <div class="debt-income-ratio-calc calc">
                                <h3>Debt to Income Ratio</h3>

                                <p>The debt to income ratio gives an indication of the sustainability of the debt load of your business.</p>
                                <p>Use information from your business' annual profit and loss and balance sheet to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Sales:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputSales} name="debt-inc1" onChange={(e) => this.setValue('inputSales', e.target.value)}/></div>
                                    
                                </div>
                                
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Other Income:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputOtherIncome} name="debt-inc2" onChange={(e) => this.setValue('inputOtherIncome', e.target.value)}/></div>
                                
                                </div>
                                
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Total Liabilities:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputTotalLiabilities} name="debt-inc3" onChange={(e) => this.setValue('inputTotalLiabilities', e.target.value)}/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {debtToIncomeRatio !== '' ? 
                                        <div class="debt-income-ratio-result calc-result bold">Debt to Income Ratio: {debtToIncomeRatio}</div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="debt-income-ratio-btn btn btn-primary" onClick={() => this.calDeptToIncomeRation()}>Calculate</button>
                                    </div>
                                </div>

                                <br />
                                <p>
                                        The ability of a business to service debt depends on
                                        its income and cost structure. The debt to income ratio
                                        provides a simple measure of the total liabilities of a
                                        business compared to its income.
                                </p>
                                <p>
                                        Both the amount and the stability of income streams have a
                                        bearing on the level of sustainable debt. In general, larger
                                        business operations and those with stable cashflow can sustain
                                        higher debt ratios provided they have efficient costs structures.
                                </p>
                                <p>
                                        The debt to income ratio can be important in the risk management
                                        process of a business.
                                </p>
                                <p>
                                        Ratios should be considered over a period of time (say three years),
                                        in order to identify trends in the performance of the business. You
                                        should seek professional advice to fully analyse the debt to income
                                        ratio.
                                </p>
                                <p>
                                        The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Debt to Income = Total Liabilities / Total Income
                                </div>
                            </div>: ''}

                            {/* interest cover */}
                            {/* {currentCalc == 'interestCover' ?
                            <div class="int-cover-calc calc">
                                <h3>Interest Cover</h3>

                                <p>The interest cover ratio gives an indication of the ability of your business to meet ongoing interest bills and therefore service debt.</p>
                                <p>Use information from your business' annual profit and loss statements to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="row">
                                    <div class="col-md-6">Input Sales:</div>
                                    <div class="col-md-4">$<input autocomplete="off" class="input-numeric" type="number" value={inputSales} onChange={(e) => this.setValue('inputSales', e.target.value)} name="inc-cv1"/></div>
                                    
                                </div>

                                <div class="row">
                                    <div class="col-md-6">Input Other Income:</div>
                                    <div class="col-md-4">$<input autocomplete="off"  class="input-numeric" type="number" value={inputOtherIncome} onChange={(e) => this.setValue('inputOtherIncome', e.target.value)} name="inc-cv2"/></div>
                                    
                                </div>

                                <div class="row">
                                    <div class="col-md-6">Input Total Liabilities:</div>
                                    <div class="col-md-4">$<input autocomplete="off"  class="input-numeric" type="number" value={inputTotalLiabilities}  onChange={(e) => this.setValue('inputTotalLiabilities', e.target.value)} name="inc-cv3"/></div>
                                    
                                </div>

                                <hr></hr>

                                <div class="row">
                                    <div class="col-md-6">
                                    {interestCover !== '' ? 
                                        <div class="inc-cv-result calc-result bold">Interest Cover: {interestCover}</div>: ''}
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="inc-cv-btn btn btn-primary" onClick={() => this.calInterestCover()}>Calculate</button>
                                    </div>
                                </div>

                                

                                <p>The following ranges are a general guide only.
                                    <ul class="text1">
                                        <li>Above 2:1 indicates a good interest cover position.</li>
                                        <li>Between 1.5 to 2:1 indicates average interest cover.</li>
                                        <li>Between 1.0 to 1.5:1 indicates some weakness that may
                                            require attention.</li>
                                        <li>Below 1 indicates that interest cover is insufficient
                                        to cover ongoing finance expenses.</li>
                                    </ul>
                                </p>
                                <p>
                                    Ratios should be considered over a period of time (say three years), in order to identify trends in the performance of the business.
                                </p>

                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Interest Cover Ratio = (Net profit before tax + Finance expenses)/Finance expenses
                                </div>
                            </div>: '' } */}

                            {/* Net Profit Margin */}
                            {currentCalc == 'netProfitMargin' ?
                            <div class="net-margin-calc calc">
                                <h3>Net Profit Margin</h3>

                                <p>
                                    For a business to survive in the long term it must generate profit.
                                    Therefore the net profit margin ratio is one of the key performance
                                    indicators for your business.
                                </p>
                                <p>Use information from your business' annual profit and loss statements to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input your sales amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={salesAmount} onChange={(e) => this.setValue('salesAmount', e.target.value)} name="net-m1"/></div>
                                    
                                </div>
                            
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input your net profit before Tax amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={netProfitBeforeTax} onChange={(e) => this.setValue('netProfitBeforeTax', e.target.value)} name="net-m2"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}
                                <br />
                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {netProfitMargin ? 
                                        <div class="net-margin-result calc-result bold">
                                            Net Profit Margin: {netProfitMargin}
                                        </div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="net-margin-btn btn btn-primary" onClick={() => this.calNetProfitMargin()}>Calculate</button>
                                    </div>
                                </div>

                                
                                <br />
                                <p>
                                    The net profit margin ratio indicates profit levels of a
                                    business after all costs have been taken into account.
                                </p>
                                <p>
                                    It is worth analysing the ratio over time. A variation in the
                                    ratio from year to year may be due to abnormal conditions or
                                    expenses. Variations may also indicate cost blowouts which
                                    need to be addressed.
                                </p>
                                <p>
                                    A decline in the ratio over time may indicate a margin squeeze
                                    suggesting that productivity improvements may need to be
                                    initiated. In some cases, the costs of such improvements may
                                    lead to a further drop in the ratio or even losses before
                                    increased profitability is achieved.
                                </p>
                                <p>
                                    The net profit margin ratio indicates profit levels of a
                                    business after all costs have been taken into account.
                                </p>
                                <p>
                                    It is worth analysing the ratio over time. A variation in the
                                    ratio from year to year may be due to abnormal conditions or
                                    expenses. Variations may also indicate cost blowouts which
                                    need to be addressed.
                                </p>
                                <p>
                                    A decline in the ratio over time may indicate a margin squeeze
                                    suggesting that productivity improvements may need to be
                                    initiated. In some cases, the costs of such improvements may
                                    lead to a further drop in the ratio or even losses before
                                    increased profitability is achieved.
                                </p>
                                <p>The calculation used to obtain the ratio is:</p>

                                <div class="bold">
                                    Net Profit Margin = (Net Profit/Sales) * 100
                                </div>

                            </div>: ''}

                            {/* Gross Profit Margin */}
                            {currentCalc == 'grossProfitMargin' ?
                            <div class="gross-margin-calc calc">
                                <h3>Gross Profit Margin</h3>
                                <p>
                                    Your business' gross profit
                                    margin is one of its key performance indicators.  The gross
                                    profit margin gives an indication on whether the average
                                    mark up on goods and services is sufficient to cover expenses
                                    and make profit.
                                </p>
                                <p>Use information from your business' annual profit and loss statements to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input your sales amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={grossSalesAmount} onChange={(e) => this.setValue('grossSalesAmount', e.target.value)} name="gross-m1"/></div>
                                    
                                </div>
                                
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input your gross profit amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={grossProfitAmount} onChange={(e) => this.setValue('grossProfitAmount', e.target.value)} name="gross-m2"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {grossProfitMargin ? 
                                        <div class="gross-margin-result calc-result bold">
                                        Gross Profit Margin: {grossProfitMargin}
                                        </div>: ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="gross-margin-btn btn btn-primary" onClick={() => this.calGrossProfitMargin()}>Calculate</button>
                                    </div>
                                </div>

                                <br />
                                <p>
                                    The gross profit margin should be stable over time.
                                    A persistent gradual decrease is likely to indicate that productivity
                                    needs to be increased to return profitability back to previous levels.
                                </p>

                                <p>The calculation used to obtain the ratio is:</p>
                                <div class="bold">
                                    Gross Profit Margin = (Gross Profit/Sales) * 100
                                </div>
                            </div>: ''}

                            {/* Return on Assets */}
                            {currentCalc == 'returnOnAssets' ?
                            <div class="return-calc calc">
                                <h3>Return on Assets</h3>

                                <p>The return on assets ratio indicates how effectively the assets of your business are working to generate profit.</p>
                                <p>Use information from your business' annual profit and loss statements and balance sheet to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input your net profit before Tax:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={netProfitBeforeTaxAssets} onChange={(e) => this.setValue('netProfitBeforeTaxAssets', e.target.value)} name="return-v1"/></div>
                                    
                                </div>
                                
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input total assets:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={totalAssets} onChange={(e) => this.setValue('totalAssets', e.target.value)} name="return-v2"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {returnOnAssets !== '' ? 
                                        <div class="return-result calc-result bold">
                                            Return On Assets: {returnOnAssets}
                                        </div>: ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="return-btn btn btn-primary" onClick={() => this.calReturnOnAssets()}>Calculate</button>
                                    </div>
                                </div>

                                <br />
                                <p>
                                    This ratio gives an indication of the
                                    effectiveness of your business in generating a profit.
                                    The higher the ratio the greater the return on assets.
                                    However this has to be balanced against such factors as risk,
                                    sustainability and reinvestment in the business through
                                    development costs.
                                </p>

                                <p>The calculation used to obtain the ratio is:</p>

                                <div class="bold">
                                    Return on Total Assets = (Net Profit Before Tax / Total Assets) * 100
                                </div>

                            </div>: ''}

                            {/* Operating Expenses to Sales */}
                            {currentCalc == 'expensesToSales' ?
                            <div class="operating-calc calc">
                                <h3>Operating Expenses to Sales</h3>

                                <p>
                                    The operating expenses to sales ratio gives an
                                    indication of the efficiency of the cost structure of your
                                    business.
                                </p>
                                <p>
                                    Use information from your business' annual profit and
                                    loss statement to input into the calculator.
                                </p>
                                <p>
                                    For information on using this calculator see below.
                                </p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Sales:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={sales} onChange={(e) => this.setValue('sales', e.target.value)} name="operating-v1"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Cost of Goods:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={costOfGoods} onChange={(e) => this.setValue('costOfGoods', e.target.value)} name="operating-v2"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Deprecation:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputDeprecation} onChange={(e) => this.setValue('inputDeprecation', e.target.value)} name="operating-v3"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Interest Expense:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputInterestExpense} onChange={(e) => this.setValue('inputInterestExpense', e.target.value)} name="operating-v4"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Lease Expense:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputLeaseExpense} onChange={(e) => this.setValue('inputLeaseExpense', e.target.value)} name="operating-v5"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Total Expenses:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={totalExpenses} onChange={(e) => this.setValue('totalExpenses', e.target.value)} name="operating-v6"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {operatingExpensesToSales ? 
                                        <div class="operating-result calc-result bold">
                                            Operating Expenses to Sales: {operatingExpensesToSales}
                                        </div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="operating-btn btn btn-primary" onClick={() =>this.calExpensesToSales()}>Calculate</button>
                                    </div>
                                </div>

                                <br />
                                <p>
                                    The operating expenses to sales ratio provides an efficiency
                                    measure of the cost structure of a business.
                                    It gives an indication  of the ability of a business
                                    to convert income into profit. Businesses with low ratios
                                    will generate more profit than others.
                                </p>
                                <p>
                                    In general business operations with larger and more stable
                                    cashflow can sustain higher ratios than smaller and less
                                    stable operations. Scale and income stability are important
                                    considerations though it is up to the management of a business
                                    to monitor costs in an appropriate manner whatever its size.
                                </p>
                                <p>
                                    There should be a balance between reducing or maintaining tight
                                    control of running costs in the short term and reinvestment in
                                    the future. This balance needs to be considered in terms of the
                                    current needs and strategy of the business and where it is
                                    heading in the medium to long term. You should seek professional
                                    advice in considering these issues and this ratio.
                                </p>
                                <p>
                                    Ratios should be considered over a period of time (say three
                                    years), in order to identify trends in the performance of the
                                    business.
                                </p>

                                <p>The calculation used to obtain the ratio is:</p>

                                <div class="bold">
                                    Operating Expenses to Sales = (Cost of Goods Sold + Total Expenses - (Finance + Depreciation))/Sales * 100
                                </div>
                            </div>: ''}

                            {/* Sales per employee  */}
                            {currentCalc == 'salesPerEmployee' ?
                            <div class="sales-emp-calc calc">
                                <h3>Sales per employee</h3>

                                <p>This ratio compares the dollar volume of sales against the total full time employee equivalent of people working in the business.</p>
                                <p>Use information from your business' annual profit and loss statement and your employee records for that year to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Sales:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputSalesPerEmp} onChange={(e) => this.setValue('inputSalesPerEmp', e.target.value)} name="sales-emp-v1"/></div>
                                    <div class="calculator-info">*</div>
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Average Number of Full Time Employees:</div>
                                    <div class="calculator-input"><input autocomplete="off"  class="input-numeric" type="number" value={inputAvgNumberFTEmp} onChange={(e) => this.setValue('inputAvgNumberFTEmp', e.target.value)} name="sales-emp-v2"/></div>
                                    <div class="calculator-info"></div>
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Average Number of Part Time Employees:</div>
                                    <div class="calculator-input"><input autocomplete="off"  class="input-numeric" type="number" value={inputAvgNumberPTEmp} onChange={(e) => this.setValue('inputAvgNumberPTEmp', e.target.value)} name="sales-emp-v3"/></div>
                                    <div class="calculator-info"></div>
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Total average weekly hours worked for all part timers:</div>
                                    <div class="calculator-input"><input autocomplete="off"  class="input-numeric" type="number" value={inputAvgWeeklyHours} onChange={(e) => this.setValue('inputAvgWeeklyHours', e.target.value)} name="sales-emp-v4"/></div>
                                    <div class="calculator-info"></div>
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Number of owners/directors:</div>
                                    <div class="calculator-input"><input autocomplete="off"  class="input-numeric" type="number" value={numberOfOwners} onChange={(e) => this.setValue('numberOfOwners', e.target.value)} name="sales-emp-v5"/></div>
                                    <div class="calculator-info"></div>
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {salesPerEmployee !== '' ? 
                                        <div>
                                        <div class="sales-emp-result calc-result bold">Sales per Full Time Equivalent  {salesPerEmployee}</div>
                                        <div class="sales-emp-result calc-result bold">Number of Full Time Equivalents {numberOfFullTime}</div>
                                        </div>
                                        : ''}
                                        
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="sales-emp-btn btn btn-primary" onClick={() => this.calSalesPerEmp()}>Calculate</button>
                                    </div>
                                </div>

                                <br />
                                <p>
                                    You will need to consider whether people are employed on a full
                                    time or part time basis when using the calculator. For example,
                                    the number of people employed in a business could amount to 3.6
                                    including 2 owners, one full time employee and one part time.
                                </p>
                                <p>
                                    If you have part time employees, the calculator estimates the
                                    number of full time equivalents based on a 40 hour week.
                                </p>
                                <p>
                                    The ratio provides a useful productivity measure. It can be used
                                    to assist monitor ongoing performance. It can also assist
                                    determine the level of sales that a business needs to generate
                                    when increasing staffing levels.
                                </p>
                                <p>
                                    It is worth considering the role of employees when using the
                                    ratio. The degree of specialisation of employees tends to
                                    increase with larger businesses. Different employees will
                                    undertake varying roles such as sales, production,
                                    administration or management. Personnel directly involved in
                                    sales will tend to have a stronger influence on the ratio than
                                    those in other roles.
                                </p>
                                <p>
                                    Ratios should be considered over a period of time (say three
                                    years), in order to identify trends in the performance of the
                                    business. You should seek professional advice when analysing or
                                    acting upon this ratio.
                                </p>
                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Sales per Employee = Sales / No. of people employed (owners & employees)
                                </div>
                            </div>: ''}

                            {/* Working Capital */}
                            {currentCalc == 'workingCapital' ?
                            <div class="working-calc calc">
                            <h3>Working Capital</h3>

                            <p>The working capital ratio can give an indication of the ability of your business to pay its bills.</p>
                            <p>Use information from your business' annual balance sheet to input into the calculator.</p>
                            <p>For information on using this calculator see below.</p>

                            <div class="calculator-info-wrapper">
                                <div class="calculator-label">Input current assets amount:</div>
                                <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputCurrentAssetsAmount} onChange={(e) => this.setValue('inputCurrentAssetsAmount', e.target.value)} name="working-v1"/></div>
                                
                            </div>

                            <div class="calculator-info-wrapper">
                                <div class="calculator-label">Input current liabilities amount:</div>
                                <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputCurrentLiabltiesAmount} onChange={(e) => this.setValue('inputCurrentLiabltiesAmount', e.target.value)} name="working-v2"/></div>
                                
                            </div>

                            {/* <hr></hr> */}

                            <div class="calculator-debt-ratio">
                                <div class="calculator-total-debt">
                                    {workingCapital !== '' ? <div class="working-result calc-result bold">Working Capital Ratio: {workingCapital}</div> : ''}
                                </div>
                                <div class="calculator-button-wrapper">
                                    <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="working-btn btn btn-primary" onClick={() => this.calWorkingCapital()}>Calculate</button>
                                </div>
                            </div>
                            
                            <br />
                            <p>
                                Generally a working capital ratio
                            of 2:1 is regarded as desirable. However the circumstances
                            of every business vary and you should consider how your
                            business operates and set an appropriate benchmark ratio.
                            </p>


                            <p>
                                A stronger ratio indicates a better ability to meet
                            ongoing and unexpected bills therefore taking the pressure off your
                            cash flow. Being in a liquid position can also have
                            advantages such as being able to negotiate cash discounts
                            with your suppliers.
                            </p>

                            <p>
                                A weaker ratio may indicate that your business
                            is having greater difficulties meeting its short-term commitments
                            and that additional working capital support is required.
                            Having to pay bills before payments are received
                            may be the issue in which case an

                            overdraft could assist. Alternatively building up a reserve of cash
                            investments may create a sound working capital buffer.
                            </p>

                            <p>
                                Ratios should be considered over a period of time
                            (say three years), in order to identify trends in the performance
                            of the business.
                            </p>

                            <p>The calculation used to obtain the ratio is:</p>
                            <div class="bold">
                                Working Capital Ratio = Current Assets / Current Liabilities
                            </div>

                            </div>: ''}

                            {/* Quick Assets Ratio */}
                            {currentCalc == 'quickAssetsRatio' ?
                            <div class="quick-calc calc">
                                <h3>Quick Assets Ratio</h3>

                                <p>The quick assets ratio gives an indication of the level of liquid assets that can be used to meet short term liabilities.</p>
                                <p>Use information from your business' annual balance sheet statement to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input Stock:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputStock} onChange={(e) => this.setValue('inputStock', e.target.value)} name="quick-v1"/></div>
                                    
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input current assets:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputCurrentAssets} onChange={(e) => this.setValue('inputCurrentAssets', e.target.value)} name="quick-v2"/></div>
                                    
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input current liabilities:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputCurrentLiablties} onChange={(e) => this.setValue('inputCurrentLiablties', e.target.value)} name="quick-v3"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {quickAssetsRatio !== '' ? <div class="quick-result calc-result bold">Quick Assets Ratio:  {quickAssetsRatio} </div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="quick-btn btn btn-primary" onClick={() => this.calQuickAssetRatio()}>Calculate</button>
                                    </div>
                                </div>
                                
                                <br />
                                <p>
                                    The quick assets ratio provides a more conservative measure
                                    than the working capital ratio in that it excludes stock
                                    (inventory). A ratio greater than 1:1 (i.e. 2:1) indicates that
                                    current liabilities can be met from current assets without
                                    having to liquidate stock.
                                </p>
                                <p>
                                    Ratios should be considered over a period of time (say three
                                    years), in order to identify trends in the performance of the
                                    business.
                                </p>
                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Quick Assets Ratio = (Current Assets - Stock) / Current Liabilities
                                </div>


                            </div>: ''}

                            {/* Stock Turnover */}
                            {currentCalc == 'stockTurnover' ?
                            <div class="stock-calc calc">
                                <h3 class="mb30">Stock Turnover</h3>

                                <p>The stock turnover ratio indicates how quickly your business is turning over stock.</p>
                                <p>Use information from your business' annual profit and loss statements and balance sheet to input into the calculator.</p>
                                <p>For information on using this calculator see below.</p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input cost of goods sold:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={costOfGoodsSold} onChange={(e) => this.setValue('costOfGoodsSold', e.target.value)} name="stock-v1"/></div>
                                    
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input opening stock:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputOpeningStocks} onChange={(e) => this.setValue('inputOpeningStocks', e.target.value)} name="stock-v2"/></div>
                                    
                                </div>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input closing stock:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputClosingStocks} onChange={(e) => this.setValue('inputClosingStocks', e.target.value)} name="stock-v3"/></div>
                                    
                                </div>

                                {/* <hr></hr> */}

                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {stockTurnOver !== '' ? <div class="stock-result calc-result bold">Stock Turnover Ratio: {stockTurnOver}</div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="stock-btn btn btn-primary" onClick={() => this.calStockTurnover()}>Calculate</button>
                                    </div>
                                </div>
                                
                                <br />
                                <p>
                                    A high ratio may indicate positive factors
                                    such as good stock demand and management. A low ratio may
                                    indicate that either stock is naturally slow moving or
                                    problems such as the presence of obsolete stock or good
                                    presentation.

                                    A low ratio can also be indicative of potential stock
                                    valuation issues.
                                    It is a good idea to monitor the ratio over
                                    consecutive financial years to determine if a trend is
                                    developing.
                                </p>
                                <p>
                                    It can be useful to compare this financial
                                    ratio with the working capital ratio. For example business operations
                                    with low stock turnover tend to require higher working capital.
                                </p>
                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>
                                <div class="bold">
                                    Stock Turnover Ratio = Cost of Goods / Average Stock
                                </div>

                            </div>: ''}

                            {/* Debtor Ageing Ratio */}
                            {currentCalc == 'debtorAgeingRatio' ?
                            <div class="debtor-calc calc">
                                <h3>Debtor Ageing Ratio</h3>
                                <p>
                                    The debtor ageing ratio indicates the average time it takes your
                                    business to collect its debts.  It's worth looking at this
                                    ratio over a number of financial years to monitor performance
                                    trends.
                                </p>
                                <p>
                                    Use information from your annual profit and loss statement along with
                                    the trade debtors figure from your balance sheet for that financial
                                    year to calculate this ratio.
                                </p>
                                <p>
                                    For information on using this calculator see below.
                                </p>

                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input trade debtors amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputTradeDebtors} onChange={(e) => this.setValue('inputTradeDebtors', e.target.value)} name="debtor-v1"/></div>
                                    
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input sales amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputSalesAging} onChange={(e) => this.setValue('inputSalesAging', e.target.value)} name="debtor-v2"/></div>
                                    
                                </div>
                                {/* <hr></hr> */}
                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {debtorsAgingRation !== '' ? <div class="debtor-result calc-result bold">Debtor Ageing Ratio: {debtorsAgingRation}</div> : ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="debtor-btn btn btn-primary" onClick={() => this.calDebtorsAgingRation()}>Calculate</button>
                                    </div>
                                </div>
                                
                                <br />
                                <p>A ratio that is lengthening can be the
                                    result of some debtors slowing down in their payments.
                                    Economic factors, such as a recession, can also influence
                                    the ratio. Tightening your business' credit control
                                    procedures may be required in these circumstances.
                                </p>
                                <p>
                                    The debtor ageing ratio has a strong impact on
                                business operations particularly working capital. Maintaining
                                a running total of your debtors by ageing (eg. current,
                                30 days, 60 days, 90 days) is a good idea, not just in
                                terms of making sure you are getting paid for the work or
                                goods you are supplying but also in managing your working capital.
                                </p>
                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Debtor Ageing Ratio (in days) = (Trade debtors / Sales) * 365
                                </div>

                            </div>: ''}

                            {/* Creditor ageing ratio */}
                            {currentCalc == 'credtorAgeingRation' ?
                            <div class="creditor-calc calc">
                                <h3>Creditor ageing ratio</h3>
                                <p>
                                    The creditor ageing ratio indicates the average time it
                                    takes for your business to pay its bills.
                                </p>
                                <p>
                                    Use information from your annual
                                    profit and loss statement along with
                                    the trade creditors figure from your balance sheet for that
                                    financial year to calculate this ratio.
                                </p>
                                <p>
                                    For information on using this calculator see below.
                                </p>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input trade creditors amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off" class="input-numeric" type="number" value={inputTradeCreditors} onChange={(e) => this.setValue('inputTradeCreditors', e.target.value)} name="creditor-v1"/></div>
                                    
                                </div>
                                <div class="calculator-info-wrapper">
                                    <div class="calculator-label">Input purchases amount:</div>
                                    <div class="calculator-input">$<input autocomplete="off"  class="input-numeric" type="number" value={inputPurchaseAmount} onChange={(e) => this.setValue('inputPurchaseAmount', e.target.value)} name="creditor-v2"/></div>
                                    
                                </div>
                                {/* <hr></hr> */}
                                <div class="calculator-debt-ratio">
                                    <div class="calculator-total-debt">
                                        {creditorsAgingRation !== '' ? <div class="creditor-result calc-result bold">Creditor Ageing Ratio: {creditorsAgingRation}</div>: ''}
                                    </div>
                                    <div class="calculator-button-wrapper">
                                        <button class="btn btn-danger btn-reset" onClick={() => this.resetValue()}>Reset</button>&nbsp;&nbsp;<button class="creditor-btn btn btn-primary" onClick={() => this.calCreditorsAgingRation()}>Calculate</button>
                                    </div>
                                </div>
                                <br />
                                <p>
                                    This ratio provides an indication
                                    of the average time it takes for your business to pay its bills. It's
                                    worth looking at the figure over a number of financial years
                                    to see if a trend is developing. A lengthening in the ratio
                                    could indicate a problem with working capital, such as
                                    decreasing stock turnover or slower debt collection.
                                </p>
                                <p>
                                    The calculation used to obtain the ratio is:
                                </p>

                                <div class="bold">
                                    Creditor Ageing Ratio (in days) = (Trade Creditors / Purchases ) * 365
                                </div>

                            </div>: ''}

                            <div class="cal-footer">
                                <p><span className='bold'>NOTE:</span> The calculator is provided for illustrative purposes only and the calculations are based on the accuracy of the information provided by you. The information about the calculators and the results of the calculations are necessarily general and are only intended as a guide. When deciding on what your business will do, many factors need to be considered, including your business' situation and financial position.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="footer_inner">
                        <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/metro-facebook.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-twitter.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-youtube.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-linkedin.svg"></img>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

          </div>
        </div>
        </Fragment>
    )
  }
}

export default generic;