import axios from 'axios';
import moment from 'moment';


export default async function getRefreshToken() {    
    const refreshtoken = localStorage.getItem('refresh')
    const expires_at = localStorage.getItem('expires_at')
    const current_time = moment().format('YYYY-MM-DDTHH:mm:ss.sssZ')
    var a = moment(current_time);//now
    var b = moment(expires_at);

    if(b.diff(a, 'minutes') <= 1){
        var data = {
            refresh: refreshtoken
        }
    
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/accounts/refreshtoken/`,
            data: data
        };
    
        await axios(config)
        .then(async (response) => {
            if(response && response.data && response.data.access){
                localStorage.setItem('access', response.data.access);
                localStorage.setItem('expires_at',moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.sssZ'))
            }
            return
        })
        .catch(function (error) {
            console.log(error);
        }); 
    }else{
        return
    }
   
  }