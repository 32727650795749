import React from 'react';
import { Link } from 'react-router-dom'
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';

export default function contact(props) {
  return (
    <React.Fragment>
      <div className="for_innerpages">
      <div className="wrapper">
      {/* <!-- Sidebar  --> */}
      <Sidebar handleSidebar={props.handleSidebar} openSidebar={props.openSidebar}></Sidebar>

        {/* <!-- Page Content  --> */}
        <div id="content" className="layoutset">

            <div className="content_board_main_right">
                <div className="page_header_set">
                    {/* <!-- Header  --> */}
                    <Header></Header>
                    <div className="page_breadcrumbs">
                        <h3 className="title">Home</h3>
                        <div className="breadcrumbs_link">
                            <div className="img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                  <g id="home" transform="translate(1 1)">
                                    <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                  </g>
                                </svg>
                            </div>
                            <span><Link to='/dashboard'>Home</Link></span><span>-</span><span>Contact us</span>
                        </div>
                    </div>
                </div>

                <div className="section_page_content">
                    <div className="white_box">
                        <h1 className="title">Get in touch</h1>
                        <p className="pera">Have an inquiry or some feedback for us ? Fill out the form below to contact our team.</p>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="form_section">
                                    
                                    <form>
                                        <div className="form-group mb-2">
                                            <label>Full Name</label>
                                            <input type="text" name="" className="form-control" placeholder="Enter full name"></input>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Email</label>
                                            <input type="text" name="" className="form-control" placeholder="Enter your email"></input>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Message</label>
                                            <textarea type="text" name="" className="form-control textarea" placeholder="Enter your message"></textarea>
                                        </div>

                                        <div className="auth_btn">
                                            <a href="#" className="theme_btn">GET IN TOUCH</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5"></div>
                        </div>
                    </div>
                </div>
                

            </div>

            <footer>
                <div className="container">
                    <div className="footer_inner">
                        <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/metro-facebook.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-twitter.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-youtube.svg"></img>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="assets/images/awesome-linkedin.svg"></img>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>

    </div>
      </div>
    </React.Fragment>
  )
}
