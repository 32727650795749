import React from "react";
import { Link } from 'react-router-dom'

class Sidebar extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openSidebar: true
        }
    }

    render() {

        const homeModuleArr = ["dashboard", "keynumbers", "conveyorTool", "quotesRegister", "workInProgress", "workInPlanner", "cashInRegister"]
        const calcModuleArr = ["calculators", "breakEvenAnalyzer", "generic"]
        
        return (
            // <div className="for_innerpages">
            < >
            {/* <!-- Sidebar  --> */}
            <nav id="sidebar" className={`${!this.props.openSidebar && "active"}`} >
                <div className="sidebar-header">
                    <h3>
                        <a href="javascript:void(0);"><img src="assets/images/bbg_logo@2x.png"></img></a>
                        <button type="button" id="sidebarCollapse" className="btn" onClick={() => this.props.handleSidebar()}>                        
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                <g id="bar-chart-2" transform="translate(1 13) rotate(-90)">
                                <line id="Line_1" data-name="Line 1" y1="10" transform="translate(12 6)" fill="none" stroke="#172b4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <line id="Line_2" data-name="Line 2" y1="16" transform="translate(6)" fill="none" stroke="#172b4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <line id="Line_3" data-name="Line 3" y1="6" transform="translate(0 10)" fill="none" stroke="#172b4d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>  
                        </button>
                    </h3>
                </div>
    
                <ul className="list-unstyled components">
                    <li>
                    <Link to="/dashboard" className={`${homeModuleArr.includes(window.location.pathname.split("/")[1]) && "active"}`}>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1921 10.22L11.0676 4.3427C10.738 4.07064 10.2619 4.07064 9.93219 4.3427L2.80776 10.22C2.6022 10.3895 2.48324 10.6418 2.48324 10.9081V19.7758C2.48324 20.2686 2.88258 20.668 3.37543 20.668H17.6242C18.117 20.668 18.5164 20.2686 18.5164 19.7758V10.9081C18.5166 10.6418 18.3976 10.3895 18.1921 10.22ZM11.4433 18.8836H9.55663V13.944H11.4432L11.4433 18.8836ZM16.7322 18.8836H13.2277V13.0518C13.2277 12.5589 12.8284 12.1596 12.3355 12.1596H8.66444C8.1716 12.1596 7.77225 12.5589 7.77225 13.0518V18.8836H4.26773V11.3288L10.5 6.18751L16.7322 11.3288V18.8836Z" fill="#7764E4"></path>
                                    <path d="M20.6655 8.43012C18.6781 6.83821 16.8043 5.31602 14.7698 3.64073C14.162 3.09459 13.5232 2.57748 12.9051 2.07714C12.2999 1.58726 11.6741 1.08062 11.0968 0.561008C10.7573 0.255522 10.2424 0.255522 9.90304 0.561008C9.32562 1.08062 8.6999 1.58726 8.09475 2.07714C7.47676 2.57736 6.83796 3.09459 6.23008 3.64073C4.19565 5.31614 2.32181 6.83821 0.334487 8.43012C-0.0501061 8.7381 -0.112203 9.29971 0.1959 9.68418C0.503884 10.0687 1.06561 10.1309 1.44996 9.82277C3.44871 8.22194 5.33277 6.6913 7.37946 5.00578C7.38957 4.99757 7.39944 4.989 7.4092 4.9802C7.98662 4.46059 8.61234 3.95394 9.21749 3.46407C9.64086 3.12147 10.0739 2.7709 10.5 2.40855C10.9261 2.7709 11.3592 3.12147 11.7825 3.46407C12.3876 3.95394 13.0135 4.46059 13.5908 4.9802C13.6005 4.989 13.6104 4.99757 13.6205 5.00578C15.6671 6.6913 17.5513 8.22194 19.55 9.82277C19.7146 9.95457 19.9115 10.0186 20.1072 10.0186C20.3687 10.0186 20.6279 9.90413 20.8041 9.68406C21.1122 9.29971 21.0501 8.7381 20.6655 8.43012Z" fill="#7764E4"></path>
                                </svg>
                            </span>
                            <span>Home</span>
                    </Link>
                    </li>
                    <li>
                        <Link to="/contact" className={`${window.location.pathname.split("/")[1] === "contact" && "active"}`}>
                            <span><img src="assets/images/mail.svg"></img></span>
                            <span>Contact us</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="" className={`${window.location.pathname.split("/")[1] === "" && "active"}`}>
                            <span><img src="assets/images/package.svg"></img></span>
                            <span>Trends</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/calculators" className={`${calcModuleArr.includes(window.location.pathname.split("/")[1]) && "active"}`}>
                            <span><img src="assets/images/calculator.svg"></img></span>
                            <span>Calculators</span></Link>
                    </li>
                    <li>
                        <Link to="" className={`${window.location.pathname.split("/")[1] === "" && "active"}`}>
                            <span><img src="assets/images/settings.svg"></img></span>
                            <span>Settings</span>
                        </Link>
                    </li>
                </ul>
            </nav>
            {/* </div> */}
           </>
        )
    }
}

export default Sidebar;