// import React and DatePicker
import React, { useState } from "react";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
// import required css from library
// import "react-datepicker/dist/react-datepicker.css";

// main implementation. using selected and onChange as main props to get and change the selected date value
const App = () => {
  // const [startDate, setStartDate] = useState(new Date());
  // return (
  //   <DatePicker 
  //     selected={startDate} 
  //     onChange={date => setStartDate(date)} 
  //   />
  // );

  // disable weekends
const disableWeekends = current => {
  return current.day() !== 0 && current.day() !== 6;
}

return (
  <DatePicker
    isValidDate={disableWeekends}
  />
);
};

export default App;