import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Static/header";
import Sidebar from "../Static/sidebar";
import Footer from "../../components/footer/footer";
import axios from "axios";
import moment from "moment";
import { map as _map } from "lodash";
import getRefreshToken from "../Middleware/getRefreshToken";
import DatePicker from "react-datetime";

const CashInRegister = (props) => {
  const [tableData, setTableData] = useState({});
  // const [weekStartDate, setWeekStartDate] = useState(
  //   moment().format("DD/MM/YYYY")
  // );
  // const [weekEndDate, setWeekEndDate] = useState(moment().format("DD/MM/YYYY"));

  useEffect(() => {
    getCashInRegister();
  }, []);



  // const exportChart = (e) => {
  //   e.preventDefault();
  // };

  const getWeekend = () => {
    const weekEnd = moment().endOf("isoweek").toDate();
    var endDate = [];
    var endDateForApiFormat = []
    for (var i = 0; i <= 52; i++) {
      const date = moment(weekEnd).add(i, "weeks").format("DD/MMM/YY");
      endDate.push(date.split("/").join(" "));

      const dateForApi = moment(weekEnd).add(i, "weeks").format("DD-MM-YYYY");
      endDateForApiFormat.push(dateForApi)
    }
    const sortedWeekEndArr = [...new Set(endDate)];
    const sortedWeekEndApiFormatArr = [...new Set(endDateForApiFormat)];

    return {weekEnds: sortedWeekEndArr, dateForApi: sortedWeekEndApiFormatArr};
  };

  const {weekEnds, dateForApi} = getWeekend();

  const getCashInRegister = async () => {
    await getRefreshToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/cash-in/`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
      data: {
        "start_date": moment().format('YYYY-MM-DD'),
        "end_date": moment(dateForApi[dateForApi.length -1],"DD-MM-YYYY").format('YYYY-MM-DD'),
      }
    };

    axios(config).then((res) => setTableData(res.data.datas));
  };

  // const setStartDate = (date) => {
  //   setWeekStartDate(moment(date).format("YYYY-MM-DD"));
  // };

  // const setEndDate = (date) => {
  //   setWeekEndDate(moment(date).format("YYYY-MM-DD"));
  // };
  // console.log("check table data ::", tableData, dateForApi)
  return (
    <Fragment>
      <div className="for_innerpages">
        <div className="wrapper">
          {/* <!-- Sidebar  --> */}
          <Sidebar handleSidebar={props.handleSidebar} openSidebar={props.openSidebar} />

          {/* <!-- Page Content  --> */}
          <div id="content" className="layoutset">
            <div className="content_board_main_right">
              <div className="page_header_set">
                {/* <!-- Header  --> */}
                <Header />
                <div className="page_breadcrumbs">
                  <h3 className="title">Home</h3>
                  <div className="breadcrumbs_link">
                    <div className="img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.304"
                        height="30.115"
                        viewBox="0 0 27.304 30.115"
                      >
                        <g id="home" transform="translate(1 1)">
                          <path
                            id="Path_435"
                            data-name="Path 435"
                            d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z"
                            transform="translate(-3 -2)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                          <path
                            id="Path_436"
                            data-name="Path 436"
                            d="M9,26.058V12h8.435V26.058"
                            transform="translate(-0.565 2.058)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </g>
                      </svg>
                    </div>
                    <span>
                      <Link to="/dashboard">Home</Link>
                    </span>
                    <span>-</span>
                    <span>
                      <Link to="/conveyorTool">Business Conveyor Tool</Link>
                    </span>
                    <span>-</span>
                    <span>Cash In Register</span>
                  </div>
                </div>
              </div>
              <div className="section_page_content dateHeight cashIn-register-section">
                <div className="tab_section">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="data"
                      role="tabpanel"
                      aria-labelledby="data-tab"
                    >
                      <div className="tab_filter">
                        <h3 className="bold_text">Cash In Register</h3>
                        {/* <div className="dateRangeSelection">
                          <div className="startDate select_parent common-field-sec">
                            <label>Week Start:</label>
                            <DatePicker
                              // initialValue={this.state.weekStartDate == "" ? new Date(startdate) : new Date(this.state.weekStartDate)}
                              initialValue={moment(weekStartDate).format(
                                "DD/MM/YYYY"
                              )}
                              dateFormat="DD/MM/YYYY"
                              className="select_box calendar_control"
                              onChange={(date) => setStartDate(date)}
                              // isValidDate={disableWeekends}
                              timeFormat={false}
                              // inputProps={{readOnly: true}}
                              closeOnSelect={true}
                            />
                          </div>
                          <div className="endDate select_parent common-field-sec">
                            <label>Week End:</label>
                            <DatePicker
                              // initialValue={this.state.weekStartDate == "" ? new Date(startdate) : new Date(this.state.weekStartDate)}
                              initialValue={moment(weekEndDate).format(
                                "DD/MM/YYYY"
                              )}
                              dateFormat="DD/MM/YYYY"
                              className="select_box calendar_control"
                              onChange={(date) => setEndDate(date)}
                              // isValidDate={disableWeekends}
                              timeFormat={false}
                              // inputProps={{readOnly: true}}
                              closeOnSelect={true}
                            />
                          </div>
                        </div> */}
                        {/* <div className="actoin_sec">
                          <form>
                            <input
                              className="importFile"
                              type={"file"} 
                              accept={".csv"}
                            />
                            <button type="submit" onClick={() => exportChart()}>
                              Submit CSV
                            </button>
                          </form>
                        </div> */}
                      </div>
                      <div className="theme_table table-responsive table-striped th_center cashIn_table">
                        <table className="table width100" id="quote_table">
                          <thead>
                            <tr>
                              <th>DATE</th>
                              {_map(weekEnds, (date, i) => {
                                return <th>{date}</th>;
                              })}
                              <th className="">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="bold_text">WIP Planner</td>
                              {_map(weekEnds, (date, i) => {
                                return <td className="text-center bold_text">--</td>;
                              })}
                              <td className="text-center bold_text">--</td>
                            </tr>
                              {
                                _map(tableData, (tdata, j) => {
                                  return _map(tdata.wip, (wipData, k) => {
                                    let rowTotal = 0;
                                    return( 
                                    <tr> 
                                      <td className="" style={{wordWrap: "nowrap", minWidth: "400px"}}>{wipData.wip_title}</td>
                                      {
                                        _map(dateForApi, (date, i) => {
                                          if(date === moment(wipData.date,"YYYY-MM-DD").format('DD-MM-YYYY')){
                                            rowTotal = rowTotal + wipData.wip_amount 
                                            return <td className="text-center bold_text">${wipData.wip_amount}</td>
                                          } else return <td className="text-center"> </td>
                                        })
                                      }
                                      <td className="bold_text">${Number(rowTotal).toFixed(2)}</td>
                                    </tr>
                                    )
                                  })
                                })
                              }
                          </tbody>
                        </table>
                        <div class="text-center">{tableData.msg}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CashInRegister;
