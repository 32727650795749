import React from 'react';

import { Bar } from 'react-chartjs-2';


export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [82, 468, 199, 822, -673, 383, -224],
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: [-388, -399, 752, -325, -779, -835, 494],
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: [-117, -144, -803, -108, -544, -517, 950],
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

console.log('data-->',data)

const App = () => {
    
    return (
        <Bar options={options} data={data} />
    );
  };
  
  export default App;