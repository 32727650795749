import React, { useState } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './Authentication/login'
import ContactUs from './ContactUs/index'
import Dashboard from './Home/dashboard'
import KeyNumbers from './Home/keynumbers'
import Register from './Authentication/register'
import ForgetPassword from './Authentication/forgetPassword'
import ResetPassword from './Authentication/resetPassword'
import Targets from './Home/targets'
import ConveyorTool from './Home/quotes'
import QuotesRegister from './Tools/quotesRegister'
import WorkInProgress from './Tools/workInProgress'
import WorkInPlanner from './Tools/workInPlanner'
import DataValidation from './Tools/dataValidation'
import CashInRegister from './Tools/cashInRegister'
import CashOutRegister from './Tools/cashOutRegister'
import Contact from './Home/contact'
import Datepicker from './Demo/datepicker'
import BarChart from './Demo/barchart'
import Caltimeline from './Demo/calenderTimeline'
import FinalCaltimeline from './Demo/finalCalenderTimeline'
import ClickOutside from './Demo/clickoutside'
import BreakEvenAnalyzer from './Calculators/breakEvenAnalyzer'
import Calculators from './Home/calculators'
import LastChance from './Demo/lastchance'
import Generic from './Calculators/generic'
import DebtorsXero from './Tools/debtorsXero'
import DebtorsQuickbook from './Tools/debtorsQuickbook'
import ProctectedRoute from './Authentication/protectedRoute'
import PageNotFound from './Home/pageNotFound'
import AuthenticatedRoutes from './authenticatedRoutes'
import CashFlow from './Tools/cashFlow'

export default function RouterPage() {
  const [openSidebar, setOpenSidebar] = useState(true)
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar)
  }
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/forgetpassword' element={<ForgetPassword/>}/>
                <Route path='/resetpassword' element={<ResetPassword/>}/>
                <Route path='*' element={<PageNotFound/>}/>

                <Route path='/' element={<AuthenticatedRoutes/>}>
                  <Route path='contactus' element={<ContactUs/>}/>
                  <Route path='dashboard' element={<Dashboard handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='keynumbers' element={<KeyNumbers handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='targets' element={<Targets/>}/>
                  <Route path='conveyorTool' element={<ConveyorTool handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='quotesRegister' element={<QuotesRegister handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='workInProgress' element={<WorkInProgress handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='workInPlanner' element={<WorkInPlanner handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='dataValidation' element={<DataValidation/>}/>
                  <Route path='cashInRegister' element={<CashInRegister handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='cashOutRegister' element={<CashOutRegister/>}/>
                  <Route path='calculators' element={<Calculators handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='breakEvenAnalyzer' element={<BreakEvenAnalyzer handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='contact' element={<Contact handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='generic' element={<Generic handleSidebar={handleSidebar} openSidebar={openSidebar}/>}/>
                  <Route path='debtorsXero' element={<DebtorsXero/>}/>
                  <Route path='debtorsQuickbooks' element={<DebtorsQuickbook/>}/>
                  {/* <Route path='cashInRegister' element={<CashInRegister />} />
                  <Route path='cashOutRegister' element={<CashOutRegister />} />
                  <Route path='cashFlow' element={<CashFlow />} /> */}
                  {/* <Route/> */}

                  <Route path='datepicker' element={<Datepicker/>}/>
                  <Route path='barchart' element={<BarChart/>}/>
                  <Route path='clickoutside' element={<ClickOutside/>}/>
                  <Route path='timeline' element={<Caltimeline/>}/>
                  <Route path='finaltimeline' element={<FinalCaltimeline/>}/>
                  <Route path='lastchance' element={<LastChance/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  )
}
