import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

class forgetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        password: "",
        confirmpassword: "",
        passwordErrorMessage: "",
        cpasswordErrorMessage: "",
        errorMessage: ""
    }
}

resetPassword =  (e) => {
    e.preventDefault()
    if(!this.state.passwordErrorMessage && !this.state.cpasswordErrorMessage){
    let token='';
    const urlString = window.location.href
    let paramString = urlString.split('?')[1];
    console.log('paramString-->',paramString)
    let queryString = new URLSearchParams(paramString);
        for(let pair of queryString.entries()) {
            console.log("Key is:" + pair[0]);
            console.log("Value is:" + pair[1]);
            token = pair[1]
        }
    console.log('state,token---->',this.state,token)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/reset_password/${token}/`, { password: this.state.password, confirmpassword: this.state.confirmpassword })
        .then(res => {
          console.log('res------------>',res)
          window.location.replace(process.env.REACT_APP_FRONTEND_URL);
       
        }).catch((err) => {
            console.log('err->',err)
            this.setState({
                errorMessage: 'No user found with this email address.'
            }) 
        })

    }

}

onClickPassword = (e) => {
    console.log('e.target.value-->',e.target.value,e.target.value.length)
    if(e.target.value == ""){
        this.setState({
            password: e.target.value,
            passwordErrorMessage: "Please enter valid password",
            errorMessage: "",
        }) 
    }else{
        this.setState({
            password: e.target.value,
            passwordErrorMessage: "",
            errorMessage: "",
        })
    }
}

onConfirmPassword = (e) => {
    console.log('e.target.value2-->',e.target.value,e.target.value.length)
    if(e.target.value == ""){
        this.setState({
            confirmpassword: e.target.value,
            cpasswordErrorMessage: "Please enter valid password",
            errorMessage: "",
        }) 
    }else if(e.target.value !== this.state.password){
        this.setState({
            confirmpassword: e.target.value,
            cpasswordErrorMessage: "New password and confirm password must be the same.",
            errorMessage: "",
        }) 
    }else{
        this.setState({
            confirmpassword: e.target.value,
            cpasswordErrorMessage: "",
            errorMessage: "",
        })
    }
}


  render() {
    const { passwordErrorMessage, cpasswordErrorMessage, errorMessage } = this.state
    console.log('this.state-->',this.state  )
    return (
      <React.Fragment>
          <div className="page">
          
          {/* <!-- header section Start --> */}
          <header className="sticky-header">
              <div className="header-nav">
                  <nav className="navbar navbar-expand-lg">
                      <div className="container">
                          <div className="site-logo">
                              <a href="#">
                                  <img src="assets/images/logo.png"></img>
                              </a>
                          </div>
                          <button className="navbar-toggler toggler_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar_toggler_icon navbar_toggler_icon_top"></span>
                              <span className="navbar_toggler_icon navbar_toggler_icon_middel"></span>
                              <span className="navbar_toggler_icon navbar_toggler_icon_bottom"></span>
                          </button>
                          <div className="collapse navbar-collapse" id="navbarToggler">
                              <div className="navbar-close">
                                  <div className="cross-wrap"><span></span><span></span></div>
                              </div>
                             
                              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                  <span></span><span></span><span></span>
                              </button>
                          </div>
                      </div>
                  </nav>
              </div>
          </header>
          {/* <!-- header section End --> */}
  
          <div className="inner_container">
               <div className="container p-0">
                   <div className="login_page">
                       <div className="login_left form_sec center-block">
                           <div className="w-100">
                               <h1>Reset Password</h1>
                               <p className="mb-2">Already have an account? <b><Link to='/'>Login</Link></b></p>
                               <form onSubmit={this.resetPassword}>
                               
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="" className="form-control" placeholder="Enter your new password" value={this.state.password} onChange={(e) => this.onClickPassword(e)}></input>
                                        {passwordErrorMessage && (<p className="error"> {passwordErrorMessage} </p>)}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>Confirm Password</label>
                                        <input type="password" name="" className="form-control" placeholder="Enter your confirm password" value={this.state.confirmpassword} onChange={(e) => this.onConfirmPassword(e)}></input>
                                        {cpasswordErrorMessage && (<p className="error"> {cpasswordErrorMessage} </p>)}
                                    </div>
                                    {errorMessage && (<p className="error"> {errorMessage} </p>)}
                                   <div className="auth_btn">
                                       <button className="theme_btn">Submit</button>
                                   </div>
                               </form>
                           </div>
                       </div>
                   </div>
               </div>
           
           </div>
         
           <footer>
                   <div className="container">
                       <div className="footer_inner">
                           <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                           <ul>
                               <li>
                                   <a href="#">
                                       <img src="assets/images/metro-facebook.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <img src="assets/images/awesome-twitter.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <img src="assets/images/awesome-youtube.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <img src="assets/images/awesome-linkedin.svg"></img>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
               </footer>
           
          
      </div>
      </React.Fragment>
    )
  }
  
}

export default forgetPassword;
