import React , { Component, Fragment } from 'react'
import Sidebar from '../Static/sidebar'
import Header from '../Static/header'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import getRefreshtoken from '../Middleware/getRefreshToken'
import OutsideClickHandler from 'react-outside-click-handler'
import moment from 'moment';
import Footer from '../../components/footer/footer';


// {
//     date
//     job_id
//     client
//     description
//     address
//     post_code
//     mn
//     status
//     quotation_win_date
//     comments
// }




class workInProgress extends Component {
    constructor(props){
        super(props);
        const defaultWIPData = JSON.parse(localStorage.getItem('defalutWorkInProgress'))
        this.state = {
            staticWIPData: defaultWIPData,
            WIPData: defaultWIPData,
            existedQuotesDates: [],
            valueAdded: false,
            currentId: '',
            currentAddedValue: '',
            firstValueAdded: false,
            currentRowId: 0,
        }  
    }

    async componentDidMount() {
        console.log('didMOunt--------------------->',)
        await this.getWIPData()
        const concernedElement = document.querySelector(".click-text");

        document.addEventListener("mousedown", (e) => {
        console.log('documents--------------->',concernedElement, e.target);
        if (concernedElement.contains(e.target)) {
            this.clickInside()
            console.log("clicked inside");
        } else {
            this.clickOutside()
            console.log("clicked outside");
        }
        });
       
    }



    clickOutside = async () => {
        const {currentId,firstValueAdded, currentRowId } = this.state
        console.log('clickoutside ------------>')
        if(firstValueAdded && currentId == ''){
            console.log('save first data post method---------->', currentRowId)
            await this.saveData(currentRowId)
            this.setState({
                currentId: '',
                firstValueAdded: false
            })
        }
    }

    clickInside = () => {
        console.log('clickinside ------------>')
    }

    getWIPData = async () => {
        await getRefreshtoken()
        const {WIPData, existedQuotesDates,staticWIPData} = this.state

        function round(num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        }

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            }
            };
            let finalData = []
        axios(config)
        .then(async (response) => {
            console.log('response----->',response.data)
            let data = response.data
            let todaysData = []
            data.map((prod,i) => {
                // console.log('inmap------>',prod,i,prod.date,moment().format("YYYY-MM-DD"))
                // if(prod.date == moment().format("YYYY-MM-DD")){
                    // console.log('if--------?',prod.completion, data[i].completion,round(prod.completion,2))
                    data[i].row_id = i + 1
                    data[i].isAdd = false
                    // data[i].isSave = false
                    data[i].isDisable = true
                    data[i].completion = round(prod.completion,2)
                    // data[i].completion = prod.completion
                    todaysData.push(prod)
                    // existedQuotesDates.push(prod.date)
                // }
            })

            // data.map((prod,i) => {
            //     console.log('remove------>',prod,i)
            //     if(prod.date !== moment().format("YYYY-MM-DD")){
            //         console.log('remove index',i,i+1)
            //         data.splice(i,i+1)
            //     }
               
            // })
            this.setState({
                currentRowId: 0
            })

            console.log('WIPData,staticWIPData-->',WIPData,staticWIPData)
            finalData = todaysData.concat(staticWIPData)
            // data.concat(WIPData)
            console.log('finalData-->',finalData)
            // data.push(WIPData[0])
            // console.log('after push',WIPData)
            this.addFinalData(finalData)
            
            
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    addFinalData = (data) => {
        this.setState({
            WIPData: data
        })
    }

    registerData = async (row_id, name, value) => {
        const {WIPData,currentAddedValue,currentId,firstValueAdded} = this.state
            if(!firstValueAdded){
                this.setState({
                    currentRowId: row_id,
                    firstValueAdded:  true
                })
            }

            // value = currentAddedValue

            console.log('i am in iff------------------------------------------------------------>',value)
            await WIPData.map((prod, i) => {

                if(prod.row_id == row_id) {
                    // console.log('match---------->',prod.row_id, row_id)
                    if((name == 'total_value_ex_gst' && /^\d+$/.test(value.substring(1))) || (name == 'total_value_ex_gst' && value.substring(1) == '')){
                        // console.log('1---->')
                        prod[name] = value.charAt(1) == '0' ? value.substring(2) : value.substring(1)
                    }else if((name == 'invoiced_to_date_ex_gst' && /^\d+$/.test(value.substring(1))) || (name == 'invoiced_to_date_ex_gst' && value.substring(1) == '')){
                        // console.log('2---->')
                        prod[name] =  value.charAt(1) == '0' ? value.substring(2) : value.substring(1)
                    }else if(name == 'start_date'){
                        prod[name] = moment(value).format('YYYY-MM-DD')
                    }else if(name == 'finish_date_est'){
                        prod[name] = moment(value).format('YYYY-MM-DD')
                    }else{
                        // console.log('3---->')
                        prod[name] = name == 'total_value_ex_gst' ? prod[name] : name == 'invoiced_to_date_ex_gst' ? prod[name] : value
                    }
                }
            })
    
            this.setState(({
                WIPData: WIPData
            }))
            // if(currentAddedValue !== ''){
            //     this.setState({
            //         currentAddedValue: ''
            //     })
            if(currentId !== ''){
                await this.saveData(row_id)
            }

            // }
        // console.log('row_id, name, value--------->',row_id,name,value)
      
    }

    addStatusValue = async (row_id, name, value) => {

        const {WIPData} = this.state

        WIPData.map((prod,i) => {
            if(prod.row_id == row_id){
                prod[name] = value
            }
        })
        this.setState({
            WIPData: WIPData
        })
        await this.saveData(row_id)
    }

    addColumn = async (row_id) => {
       
        const {WIPData} = this.state

        await WIPData.map((prod,i) => {
            // console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                prod.isAdd = false
                prod.isDisable = true
            }
        })

        var indexvalue = row_id + 1
      
        var staticQuote = JSON.parse(localStorage.getItem('defalutWorkInProgress'))
        console.log('row_id-->',row_id,indexvalue,staticQuote[0],WIPData)
        
        staticQuote[0].row_id = indexvalue
        // WIPData[indexvalue] = staticQuote[0]
        
        console.log('before dta staticQuote--->',staticQuote)
        WIPData.push(staticQuote[0])
       
        
      
        console.log('after dta--->',WIPData)
        // WIPData[row_id].isAdd = false


      
        // console.log('after adding one column',WIPData)



        this.setState({
            WIPData: WIPData,
            valueAdded: false,
            currentId: '',
            firstValueAdded: false,
            currentRowId: indexvalue
        })

    }

    deleteData = async(id, index) => {
        const {WIPData} = this.state
        // console.log('delete id---->',id,index,WIPData)
        // var newArr = WIPData
        // newArr.reverse()
        // newArr.splice(index, 1);
      
        // console.log('newArr------>',newArr)
        // console.log('reverse quotes----->',WIPData)
        // WIPData.splice(0,WIPData.length);
        var deleteIndex
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/${id}/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            }
            };

        await axios(config)
        .then(async (response) => {
          console.log('response delete -->',response)
         
          await WIPData.map((prod,i) => {
              console.log('---------->',prod.id,id)
              if(prod.id == id){
                  deleteIndex = i 
              }
          })
          console.log('deleteindex---------->',deleteIndex)
          await WIPData.splice(deleteIndex, 1);
          console.log('after delete-->',WIPData)
          await this.addFinalData(WIPData)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    saveData = async (row_id) => {
        const {WIPData,existedQuotesDates,valueAdded,currentId,currentAddedValue} = this.state
        //  if(currentAddedValue !== ''){
                this.setState({
                    currentAddedValue: ''
                })
           
         
        console.log('quotesDta-->',row_id,WIPData)
        await getRefreshtoken()
        // WIPData[index].isAdd = true
        // WIPData[index].isSave = false

        function round(num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        }

        if(!valueAdded){
            setTimeout(this.valueAddedInTable(), 5000)
        }

        let index = '',setId = ''
        await WIPData.map((prod,i) => {
            // console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                // prod.isAdd = true
                // prod.isSave = false
                index = i
                setId = prod.id
            }
        })
        // console.log('WIPData--------------------->',WIPData)
        // this.setState({
        //     WIPData:WIPData
        // })
        
        console.log('index--------->',index,this.state.WIPData)
        const data = WIPData[index]
    
        // delete data.row_id
        // delete data.id
        // delete data.isAdd
        // delete data.isSave
        // delete data.invoice_to_complete_ex_gst
        // delete data.completion
        // delete data.isValueAdded
        // delete data.isDisable
    
        if(data.start_date == ""){
            data.start_date = moment().format('YYYY-MM-DD')
        }
        if(data.finish_date_est == ""){
            data.finish_date_est = moment().format('YYYY-MM-DD')
        }
        if(data.total_value_ex_gst == ""){
            data.total_value_ex_gst = 0
        }
        if(data.invoiced_to_date_ex_gst == ""){
            data.invoiced_to_date_ex_gst = 0
        }
    
        console.log('send data------>',data,valueAdded)

        if(currentId ? currentId : setId){
            var config = {
                method: 'patch',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/${currentId ? currentId : setId}/`,
                data: data
            };
        }else{
            
            var config = {
                method: 'post',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/`,
                data: data
            };
        }
       
        await axios(config)
        .then(async (response) => {
            console.log('response if--------------------------------->',config,response)
            // await existedQuotesDates.push(response.data.date)
            await WIPData.map(async (prod,i) => {
                console.log('after response------>',prod.row_id,row_id,index,i)
                // currentId = response.data.id
                if(config.method == 'post'){
                    setTimeout(await this.setTableId(response.data.id), 5000)
                    setId = response.data.id
                }
                if(index == i){
                    console.log('in if--------------->')
                    prod.id = response.data.id
                    prod.isValueAdded = data.isValueAdded == false ? true : true 
                    prod.invoice_to_complete_ex_gst = response.data.invoice_to_complete_ex_gst == "" ? 0 :response.data.invoice_to_complete_ex_gst
                    // prod.completion = response.data.completion
                    prod.completion = round(response.data.completion,2)
                    // prod['id'] = response.data.id
                    // prod['isAdd'] = false
                    // prod['isSave'] = false
                    // prod['invoice_to_complete_ex_gst'] = response.data.invoice_to_complete_ex_gst == "" ? 0 :response.data.invoice_to_complete_ex_gst
                    // prod['completion'] = round(response.data.completion,2)
                    // prod['isDisable'] = true
                    // prod['row_id'] = row_id
                }
            })

            setId = ''
            this.setState({
                currentId: '',
            })
           
        })
        .catch(function (error) {
            console.log(error);
        });
        console.log('currentId------------------->',currentId,setId)
        // if(currentId == ''){
        //    await this.setTableId(setId)
        // }
        await this.addFinalData(WIPData)
        // await this.addColumn(row_id)

        // }
    }

    setTableId = (setId) => {
        this.setState({
            currentId: setId
        })
    }

    valueAddedInTable = () => {
        this.setState({
            valueAdded: true
        })
    }

    saveCurrentData = (value) => {
        console.log('value added-------->',value)
        this.setState({
            currentAddedValue: value
        })
    }

    changePage = (data) => {
        // var url = process.env.REACT_APP_FRONTEND_URL+'/conveyorTool'
            // console.log('afeter added ------>',process.env.REACT_APP_FRONTEND_URL,url)
            this.setState({
                staticWIPData: JSON.parse(localStorage.getItem('defalutWorkInProgress')),
                WIPData: JSON.parse(localStorage.getItem('defalutWorkInProgress')),
                existedQuotesDates: [],
                valueAdded: false,
                currentId: '',
                currentAddedValue: '',
                currentRowId: 0,
                firstValueAdded: false
            })
            // window.location.replace(url);
    }

    render() {
        const disabledate = current => {
            var current = moment(current).format('DD.MM.YYYY')
            var selectedDate = moment().format('DD.MM.YYYY')
            var startDate = moment(current, "DD.MM.YYYY");
            var endDate = moment(selectedDate, "DD.MM.YYYY");
            var diffDays = endDate.diff(startDate, 'days');
            // console.log('diffDays-->',diffDays)
            let value = diffDays > 0 ? false : true
            return value
        }


        // const disableExistedDates = current => {
        //     var current = moment(current).format('YYYY-MM-DD')
        //     existedQuotesDates.map(i => {
        //         console.log('i,curret--?',i,current)
        //         if(i == current){
        //             return true
        //         }
        //     })
        // }
        console.log('states-->',this.state)
        // console.log('options-->',options)
        // console.log('data-->',data)
        return (
            <Fragment>
                <div className="for_innerpages">
                <div className="wrapper">
                {/* <!-- Sidebar  --> */}
                <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
               {/* <div class="click-text">working</div> */}
               {/* <div style={{
                    margin: 300,
                    width: 200, height: 200, backgroundColor: 'green'
                    }}

                    // Assigning the ref to div component
                    ref={this.box}>{this.props.children}</div> */}
                {/* <!-- Page Content  --> */}
                <div id="content" className="layoutset">
        
                    <div className="content_board_main_right">
                        <div className="page_header_set">
                            {/* <!-- Header  --> */}
                            <Header></Header>
                            <div className="page_breadcrumbs">
                                <h3 className="title">Home</h3>
                                <div className="breadcrumbs_link">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                          <g id="home" transform="translate(1 1)">
                                            <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>
                                    </div>
                                    <span><Link to='/dashboard'>Home</Link></span><span>-</span><span><Link to="/conveyorTool" onClick={(e) => this.changePage(e)}>Business Conveyor Tool</Link></span><span>-</span><span>Work In Progress Register</span>
                                </div>
                            </div>
                        </div>
        
                        <div className="section_page_content common-height-div work-progress-section">
                            <div className="tab_section">

                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                        <div className="tab_filter">
                                            <h3 className='bold_text'>Work in progress Register</h3>
                                            {/* <div className='static-header mt20'>Data will save automatically</div> */}
                                        </div>
                                      
                                        <div className="theme_table table-responsive th_center quote_table">
                                            <table className="table width100" id="quote_table">
                                                <thead>
                                                    <tr>
                                                        <th width="30%">DATE</th>
                                                        <th width="5%">JOB ID</th>
                                                        <th width="5%">CLIENT</th>
                                                        <th width="5%">DESCRIPTION</th>
                                                        <th width="5%">ADDRESS</th>
                                                        <th width="5%">POST CODE</th>
                                                        <th width="5%">TOTAL <span className='smallText'>VALUE - (EX GST)</span></th>
                                                        <th width="5%">START <span className='smallText'>DATE</span></th>
                                                        <th width="5%">INVOICED TO DATE <span className='smallText'>(EXCL. GST)</span></th>
                                                        <th width="5%">INVOICE TO COMPLETION <span className='smallText'>(EXCL. GST)</span></th>
                                                        <th width="5%">COMPLETION</th>
                                                        <th width="5%">FINISH DATE <span className='smallText'>(EST)</span></th>
                                                        <th width="5%">STATUS</th>
                                                        <th width="5%">COMMENTS</th>
                                                        <th width="5%"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.WIPData.length >= 1 && this.state.WIPData.slice(0).reverse().map((prod,i) => {
                                                        return (
                                                        <tr>
                                                            <td><div className='tWidth'>{prod.date ? moment(prod.date).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}</div></td>
                                                            <td className='click-text'>
                                                            <div class="amount_box"><input  name="job_id" type="text" value={prod.job_id} 
                                                                onChange={(e) => this.registerData(prod.row_id, "job_id", e.target.value)} /></div>
                                                            {/* <OutsideClickHandler
                                                                onOutsideClick={(e) => {
                                                                    // alert('You clicked outside of this component!!!');
                                                                    
                                                                }}
                                                                >
                                                                  <input  name="job_id" type="text" value={prod.job_id} 
                                                                onChange={(e) => this.registerData(prod.row_id, "job_id", e.target.value)} />
                                                              
                                                                
                                                                </OutsideClickHandler>
                                                                 */}
                                                            </td>
                                                            <td className='click-text'><div class="amount_box"><input  name="client" value={prod.client} onChange={(e) => this.registerData(prod.row_id, "client", e.target.value)} /></div></td>
                                                            <td className='click-text'><div class="amount_box"><input  name="description" value={prod.description} onChange={(e) => this.registerData(prod.row_id, "description", e.target.value)} /></div></td>
                                                            <td className='click-text'><div class="amount_box"><input  name="address" value={prod.address} onChange={(e) => this.registerData(prod.row_id, "address", e.target.value)} /></div></td>
                                                            <td className='click-text'><div class="amount_box"><input  name="post_code" value={prod.post_code} onChange={(e) => this.registerData(prod.row_id, "post_code", e.target.value)} /></div></td>
                                                            <td className='click-text'><div class="amount_box"><input  name="total_value_ex_gst" value={'$'+prod.total_value_ex_gst} onChange={(e) => this.registerData(prod.row_id, "total_value_ex_gst", e.target.value)} /></div></td>

                                                            <td className='click-text'>
                                                            <DatePicker
                                                                    initialValue={moment().format('DD-MM-YYYY')} 
                                                                    value={prod.start_date !== null && moment(prod.start_date).format('DD-MM-YYYY')} 
                                                                    className="select_box calendar_control"
                                                                    dateFormat="DD-MM-YYYY" 
                                                                    name="start_date"
                                                                    onChange={date => this.registerData(prod.row_id, "start_date", date)} 
                                                                    isValidDate={disabledate}
                                                                    timeFormat={false}
                                                                    // inputProps={{readOnly: true, disabled: !prod.isAdd}}
                                                                    closeOnSelect={true}
                                                                    // onKeyDown={(e) => {
                                                                    //     console.log('keydoe---->',e)
                                                                    //     e.preventDefault();
                                                                    // }}
                                                                    readOnly={true}
                                                                />
                                                                
                                                                {/* <div class="amount_box"><input  name="quotation_win_date" value={prod.quotation_win_date} onChange={(e) => this.registerData(prod.id, "quotation_win_date", e.target.value)} /></div> */}
                                                                
                                                                </td>

                                                            <td className='click-text'><div class="amount_box"><input name="invoiced_to_date_ex_gst" value={'$'+ prod.invoiced_to_date_ex_gst} onChange={(e) => this.registerData(prod.row_id, "invoiced_to_date_ex_gst", e.target.value)} /></div></td>
                                                            <td className='click-text'><div class="amount_box"><input name="invoice_to_complete_ex_gst" value={'$'+ prod.invoice_to_complete_ex_gst} disabled/></div></td>
                                                            <td className='click-text'><div class="amount_box"><input name="completion" value={prod.completion ? prod.completion + ' %' : ''} disabled/></div></td>
                                                            <td className='click-text'>
                                                            <DatePicker
                                                                    initialValue={moment().format('DD-MM-YYYY')} 
                                                                    value={moment(prod.finish_date_est).format('DD-MM-YYYY')} 
                                                                    className="select_box calendar_control"
                                                                    dateFormat="DD-MM-YYYY" 
                                                                    name="finish_date_est"
                                                                    onChange={date => this.registerData(prod.row_id, "finish_date_est", date)} 
                                                                    isValidDate={disabledate}
                                                                    timeFormat={false}
                                                                    // inputProps={{readOnly: true, disabled: !prod.isAdd}}
                                                                    closeOnSelect={true}
                                                                    // onKeyDown={(e) => {
                                                                    //     console.log('keydoe---->',e)
                                                                    //     e.preventDefault();
                                                                    // }}
                                                                    readOnly={true}
                                                                />
                                                                
                                                                {/* <div class="amount_box"><input  name="quotation_win_date" value={prod.quotation_win_date} onChange={(e) => this.registerData(prod.id, "quotation_win_date", e.target.value)} / ></div> */}
                                                                
                                                                </td>

                                                            
                                                            <td className='click-text'> <select className={`select2 select_drop select_control form-control selectSmall ${prod.status == 'Complete' ? 'green' : prod.status == 'In Progress' ? 'yellow' : prod.status == 'On Hold' ? 'red' : prod.status == 'Waiting to Commence' ? 'blue' : ''}`} name="status" id="SelectPeriods" defaultValue={prod.status} value={prod.status} onChange={(e) => this.addStatusValue(prod.row_id, "status", e.target.value)} >
                                                                    <option value="" defaultChecked>Select</option>
                                                                    <option value="Complete">Complete</option>
                                                                    <option value="In Progress">In progress</option>
                                                                    <option value="On Hold">On hold</option>
                                                                    <option value="Waiting to Commence">Waiting to Commence</option>
                                                                </select></td>

                                                            <td className='click-text'><div class="amount_box"><input  name="comments" value={prod.comments} onChange={(e) => this.registerData(prod.row_id, "comments", e.target.value)} /></div></td>
                                                            <td className='click-text'>
                                                            {prod.isAdd && 
                                                                <a onClick={() => prod.isValueAdded ? this.addColumn(prod.row_id) : ''}>
                                                                    <i class="fa fa-plus add_ic" />
                                                                </a>
                                                                // : null 
                                                                // <a href="#" onClick={() => this.deleteData(prod.id, i)}>
                                                                //     <i class="far fa-trash-alt delete_ic" />
                                                                // </a> 
                                                            }
                                                            </td>
                                                        </tr>
                                                        )
                                                   })
                                               }
                                                
                                                 
                                                </tbody>
                                            </table>
                                        </div>            
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
        
                 
                </div>
                
                </div>
                
            </Fragment>
          )
    }
}

export default workInProgress;
