import React from 'react'
import { Link } from 'react-router-dom'

export default function index() {
  return (
    <React.Fragment>
         <div className="page">

{/* <!-- header section Start --> */}
<header className="sticky-header">
    <div className="header-nav">
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <div className="site-logo">
                    <a href="index.html">
                        <img src="assets/images/logo.png"></img>
                    </a>
                </div>
                <button class="navbar-toggler toggler_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar_toggler_icon navbar_toggler_icon_top"></span>
                            <span class="navbar_toggler_icon navbar_toggler_icon_middel"></span>
                            <span class="navbar_toggler_icon navbar_toggler_icon_bottom"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarToggler">
                    <div className="navbar-close">
                        <div className="cross-wrap"><span></span><span></span></div>
                    </div>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                          <Link to='/' className="nav-link">HOME</Link>
                        </li>
                        <li className="nav-item">
                          <Link to='/contactus' className="nav-link">CONTACT US</Link>
                        </li>

                        <li className="nav-item">
                          <Link to='/' className="theme_btn">SIGN IN</Link>
                        </li>

                    </ul>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span></span><span></span><span></span>
      </button>
                </div>
            </div>
        </nav>
    </div>
</header>
{/* <!-- header section End --> */}

<div className="inner_container cntmain">
    <div className="left_lable_intro_img bg-img-c"></div>
    <div className="container p-0">
        <div className="login_page contpage login_display">
            <div className="login_left form_sec">
                <div className="w-100">
                    <h1>Get in touch</h1>
                    <p>Have an inquiry or some feedback for us ? Fill out the form below to contact our team.</p>
                    <form>
                        <div className="form-group mb-2">
                            <label>Full Name</label>
                            <input type="text" name="" className="form-control" placeholder="Enter full name"></input>
                        </div>
                        <div className="form-group mb-2">
                            <label>Email</label>
                            <input type="text" name="" className="form-control" placeholder="Enter your email"></input>
                        </div>
                        <div className="form-group mb-2">
                            <label>Message</label>
                            <textarea type="text" name="" className="form-control textarea" placeholder="Enter your message"></textarea>
                        </div>

                        <div className="auth_btn">
                            <a href="#" className="theme_btn">GET IN TOUCH</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="info_section_contact">
            <div className="block_col">
                <h5>OUR ADDRESS</h5>
                <p>PO Box 3204, East Doncaster, VIC 3109</p>
            </div>
            <div className="block_col">
                <h5>OUR ADDRESS</h5>
                <p>03 9001 0878 | 03 9001 0878</p>
            </div>
        </div>
    </div>
</div>




<footer>
    <div className="container">
        <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
    </div>
</footer>

</div>
    </React.Fragment>
  )
}
