import React, { Component, Fragment} from 'react'
import '../../App.css';
import { Link } from 'react-router-dom'
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';

class breakEvenAnalyzer extends Component{
  constructor(props){
    super(props);

    this.state = {
      monthlyExpenses: '',
      grossWages: '',
      superAnnuation: '',
      profit: '',
      cogs: '',
      profitMargin: '',
      calculateValue: '',
      errorMessage: ''
    }
  }

  saveData = (name, value) => {
    console.log('saveData------------>',name,value)

    this.setState({
      [name]: value
    })
    if(name == 'cogs'){
      var gpm
      gpm = 100 - value
      console.log('gross profit margin------------->')
      this.setState({
        profitMargin: gpm
      })
    }
  }

  calculate = () => {
    const {monthlyExpenses, grossWages, superAnnuation, profit, cogs, profitMargin} = this.state

    if(monthlyExpenses == '' || grossWages == '' || profit == '' || cogs == ''){
      this.setState({
        errorMessage: 'Please enter mendatory field to calculate Break Even Analyzer'
      })
    }else {

    function round(num, decimalPlaces = 0) {
        num = Math.round(num + "e" + decimalPlaces);
        return Number(num + "e" + -decimalPlaces);
    }
    var total, breakEvenValue,divider

    total = parseInt(monthlyExpenses) + parseInt(grossWages) + parseInt(superAnnuation) + parseInt(profit)
    divider = (profitMargin/100)
    if (divider > 0){
      breakEvenValue = total/divider
      console.log('------------>',total,divider)
  
      this.setState({
        calculateValue: round(breakEvenValue,2),
        errorMessage: ''
      })
    } else{
      this.setState({
        calculateValue:'',
        errorMessage: 'Input cost of goods sold (COGS) should be less than 100.'
      })
    }
  }
  }

  resetData = () => {
    this.setState({
      monthlyExpenses: '',
      grossWages: '',
      superAnnuation: '',
      profit: '',
      cogs: '',
      profitMargin: '',
      calculateValue: ''
    })
  }

  render() {
    const {monthlyExpenses, grossWages, superAnnuation, profit, cogs, profitMargin, calculateValue, errorMessage} = this.state
    console.log('this.state----------->',this.state)
   return (
    <Fragment>
    <div className="for_innerpages">
           <div className="wrapper">
           <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
       
           <div id="content" className="layoutset">
   
               <div className="content_board_main_right">
                   <div className="page_header_set">
                       {/* <!-- Header  --> */}
                       <Header></Header>
                       <div className="page_breadcrumbs">
                           <h3 className="title">Home</h3>
                           <div className="breadcrumbs_link">
                               <div className="img">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                     <g id="home" transform="translate(1 1)">
                                       <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                       <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                     </g>
                                   </svg>
                               </div>
                               <span><Link to='/dashboard'>Home</Link></span><span>-</span><span><Link to='/calculators'>Calculators</Link></span><span>-</span><span>Break Even Analyer</span>
                           </div>
                       </div>
                   </div>
   
                  <div className="section_page_content calculators-page-content">
                    <div className="calculators-content-wrapper">
                      <div className="tab_filter">
                        <h3 className="bold_text">Break Even Analyzer</h3>
                      </div>
                      <div className="break-analyzer-wrapper">
                        <div className="row">
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input monthly expenses:</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">$</span><div class="amount_box required"><input type="number" name="monthlyExpenses" value={monthlyExpenses} onChange={(e) => this.saveData("monthlyExpenses", e.target.value)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input gross wages:</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">$</span><div class="amount_box required"><input type="number" name="grossWages" value={grossWages} onChange={(e) => this.saveData("grossWages", e.target.value)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input superannuation:</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">$</span><div class="amount_box"><input type="number" name="superAnnuation" value={superAnnuation} onChange={(e) => this.saveData("superAnnuation", e.target.value)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input profit:</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">$</span><div class="amount_box required"><input type="number" name="profit" value={profit} onChange={(e) => this.saveData("profit", e.target.value)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input cost of goods sold (COGS):</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">%</span><div class="amount_box required"><input type="number" name="cogs" value={cogs} onChange={(e) => this.saveData("cogs", e.target.value)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <div className="analyzer-fields">
                              <label>Input gross profit margin:</label>
                              <div className="analyzer-input-fields">
                                <span className="input-sign">%</span><div class="amount_box"><input type="number" name="profitMargin" value={profitMargin} onChange={(e) => this.saveData("profitMargin", e.target.value)} disabled/></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row analyzer-footer-div">
                          <div className="col-xl-8 col-md-6 analyzer-message-info">{errorMessage && (<p className="error"> {errorMessage} </p>)}{ calculateValue !== '' ? 
                          <div className="analyzer-break-even">Break even income: {calculateValue} $</div> : ''}</div>
                          <div className="col-xl-4 col-md-6 calculator-button-wrapper">
                            <button className="red_cbtn" onClick={() => this.resetData()}>Reset</button>
                            <button className="blue_cbtn" onClick={() => this.calculate()}>Calculate</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               </div>


                 <footer>
                   <div className="container">
                       <div className="footer_inner">
                           <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                           <ul>
                               <li>
                                   <a href="javascript:void(0);">
                                       <img src="assets/images/metro-facebook.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="javascript:void(0);">
                                       <img src="assets/images/awesome-twitter.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="javascript:void(0);">
                                       <img src="assets/images/awesome-youtube.svg"></img>
                                   </a>
                               </li>
                               <li>
                                   <a href="javascript:void(0);">
                                       <img src="assets/images/awesome-linkedin.svg"></img>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
               </footer>     
           </div>
   
            
           </div>
           
           </div>
</Fragment>
   )
  }
}

export default breakEvenAnalyzer;
