import React , { Component, Fragment } from 'react'
import Sidebar from '../Static/sidebar'
import Header from '../Static/header'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import getRefreshtoken from '../Middleware/getRefreshToken'
import moment from 'moment';
// import TimeLine from 'react-calendar-timeline'
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import GanttChart from '../../components/GanttChart';
import Footer from '../../components/footer/footer';


// let tasks = [
//     {
//       start: new Date(2020, 1, 1),
//       end: new Date(2020, 1, 15),
//       name: 'Idea',
//       id: 'Task 0',
//       type:'task',
//       progress: 100,
//       isDisabled: true,
//       styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
//     },
//     {
//         start: new Date(2020, 1, 1),
//         end: new Date(2020, 1, 20),
//         name: 'Idea2',
//         id: 'Task 0',
//         type:'task',
//         progress: 100,
//         isDisabled: true,
//         styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
//       }
// ]
let colorChart = [
    {
        // green - completed
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(13,127,25,1) 50%)",
        width: "100%"
    },
    {
        //yellow - in progress
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(238,135,0,1) 50%)",
        width: "100%"
    },
    {
        //red - on hold
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,26,26,1) 50%)",
        width: "100%"
    },
    {
        // blue - waiting to commence
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(96,140,228,1) 50%)",
        width: "100%"
    }
]
let columnWidth = 65;


// {
//     date
//     job_id
//     client
//     description
//     address
//     post_code
//     quotation_ex_gst
//     status
//     quotation_win_date
//     comments
// }

const staticWIPData = JSON.parse(localStorage.getItem('defalutWorkInProgress'))
const staticGroups = [
    {id:1, title: 'Complete'},
    {id:2, title: 'In Progress'},
    {id:3, title: 'On Hold'},
    {id:4, title: 'Waiting to Commence'},
]


class workInProgress extends Component {
    constructor(props){
        super(props);
        this.state = {
            WIPData: [],
            // existedQuotesDates: [],
            status: "",
            groups: [],
            items: [],
            tasks: []
        }  
    }

    componentDidMount() {
        console.log('didMOunt--------------------->',)
        // this.getWIPData()
    }

    addFinalData = (data) => {
        this.setState({
            WIPData: data
        })
    }

    registerData = async (value) => {
        console.log('value--->',value)
        this.setState({
            status: value,
            WIPData: [],
            tasks: []
        })
        await this.getWIPData()
    }

    getWIPData = async () => {
        await getRefreshtoken()
        const {WIPData, groups, items, status, tasks} = this.state

        function round(num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        }

        var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/wip-planner/`,
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                data: {
                    "filter_status": status
                }
            };
            
        let finalData = []
        axios(config)
        .then(async (response) => {
            console.log('response----->',response.data)
            let data = response.data
            // let todaysData = []
            data.map((prod,i) => {
                console.log('inmap------>',prod, status)
                // data[i].items
                if(prod.status == status){
                    console.log('match--------------------------->')
                    items.push({
                        id: 1, group: status == "Complete" ? 1 : status == "In Progress" ? 2 : status == "On Hold" ? 3 : 4, title: status, start_time: moment(prod.start_date), end_time: moment(prod.finish_date_est)
                    })
                    groups.push({
                        id: status == "Complete" ? 1 : status == "In Progress" ? 2 : status == "On Hold" ? 3 : 4,
                        title: status
                    })
                    tasks.push({
                        start: new Date(prod.start_date),
                        end: new Date(prod.finish_date_est),
                        // name: status,
                        id: status == "Complete" ? 'Task 0' : status == "In Progress" ? 'Task 1' : status == "On Hold" ? 'Task 2' : 'Task 3',
                        type:'task',
                        progress: 100,
                        isDisabled: true,
                        styles: status == "Complete" ? colorChart[0] : status == "In Progress" ? colorChart[1] : status == "On Hold" ? colorChart[2] : colorChart[3]
                    })
                    // for gantt chart
                    prod.task = {
                        start: new Date(prod.start_date),
                        end: new Date(prod.finish_date_est),
                        // name: status,
                        id: status == "Complete" ? 'Task 0' : status == "In Progress" ? 'Task 1' : status == "On Hold" ? 'Task 2' : 'Task 3',
                        type:'task',
                        progress: 100,
                        isDisabled: true,
                        styles: status == "Complete" ? colorChart[0] : status == "In Progress" ? colorChart[1] : status == "On Hold" ? colorChart[2] : colorChart[3]
                    }
                    prod.completion = round(prod.completion,2)
                    WIPData.push(prod)
                }
               
            })

            // data.map((prod,i) => {
            //     console.log('remove------>',prod,i)
            //     if(prod.date !== moment().format("YYYY-MM-DD")){
            //         console.log('remove index',i,i+1)
            //         data.splice(i,i+1)
            //     }
               
            // })
            this.setState({
                groups: groups,
                items: items,
                tasks: tasks
            })

            // console.log('WIPData-->',WIPData)
            // finalData = todaysData.concat(WIPData)
            // // data.concat(WIPData)
            // console.log('finalData-->',finalData)
            // data.push(WIPData[0])
            // console.log('after push',WIPData)
            this.addFinalData(WIPData)
            
            
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    addStatusValue = (row_id, name, value) => {

        const {WIPData} = this.state

        WIPData.map((prod,i) => {
            if(prod.row_id == row_id){
                prod[name] = value
            }
        })
        this.setState({
            WIPData: WIPData
        })
    }

    addColumn = async (row_id) => {
       
        const {WIPData} = this.state

        await WIPData.map((prod,i) => {
            console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                prod.isAdd = false
            }
        })

        var indexvalue = row_id + 1
      
        var staticQuote = JSON.parse(localStorage.getItem('defalutWorkInProgress'))
        console.log('row_id-->',row_id,indexvalue,staticQuote[0],WIPData)
        
        staticQuote[0].row_id = indexvalue
        // WIPData[indexvalue] = staticQuote[0]
        console.log('before dta staticQuote--->',staticQuote)
        WIPData.push(staticQuote[0])
       
        
      
        console.log('after dta--->',WIPData)
        // WIPData[row_id].isAdd = false


      
        // console.log('after adding one column',WIPData)



        this.setState({
            WIPData: WIPData
        })

    }

    deleteData = async(id, index) => {
        const {WIPData} = this.state
        // console.log('delete id---->',id,index,WIPData)
        // var newArr = WIPData
        // newArr.reverse()
        // newArr.splice(index, 1);
      
        // console.log('newArr------>',newArr)
        // console.log('reverse quotes----->',WIPData)
        // WIPData.splice(0,WIPData.length);
        var deleteIndex
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/${id}`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            }
            };

        await axios(config)
        .then(async (response) => {
          console.log('response delete -->',response)
         
          await WIPData.map((prod,i) => {
              console.log('---------->',prod.id,id)
              if(prod.id == id){
                  deleteIndex = i 
              }
          })
          console.log('deleteindex---------->',deleteIndex)
          await WIPData.splice(deleteIndex, 1);
          console.log('after delete-->',WIPData)
          await this.addFinalData(WIPData)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    saveData = async (row_id) => {
        const {WIPData,existedQuotesDates} = this.state
        console.log('quotesDta-->',row_id,WIPData)
        await getRefreshtoken()
        // WIPData[index].isAdd = true
        // WIPData[index].isSave = false

        function round(num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        }

        let index
        await WIPData.map((prod,i) => {
            console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                // prod.isAdd = true
                // prod.isSave = false
                index = i
            }
        })
        // console.log('WIPData--------------------->',WIPData)
        // this.setState({
        //     WIPData:WIPData
        // })
        
        console.log('index--------->',index,this.state.WIPData)
        const data = WIPData[index]
    
        // delete data.row_id
        // delete data.id
        // delete data.isAdd
        // delete data.isSave
        // delete data.invoice_to_complete_ex_gst
        // delete data.completion
        // delete data.isValueAdded
        // delete data.isDisable
    
        if(data.start_date == ""){
            data.start_date = moment().format('YYYY-MM-DD')
        }
        if(data.finish_date_est == ""){
            data.finish_date_est = moment().format('YYYY-MM-DD')
        }
        if(data.total_value_ex_gst == ""){
            data.total_value_ex_gst = 0
        }
        if(data.invoiced_to_date_ex_gst == ""){
            data.invoiced_to_date_ex_gst = 0
        }
    
        console.log('send data------>',data)
        if(data.isValueAdded){
            var config = {
                method: 'patch',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/${data.id}`,
                data: data
            };
        }else{
            var config = {
                method: 'post',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/work-progress/`,
                data: data
            };
        }
       
        await axios(config)
        .then(async (response) => {
            console.log('response if----->',response)
            existedQuotesDates.push(response.data.date)
            WIPData.map((prod,i) => {
                console.log('after response------>',prod.row_id,row_id,index,i)
                if(index == i){
                    console.log('in if--------------->')
                    prod.id = response.data.id
                    prod.isValueAdded = data.isValueAdded == false ? true : true 
                    prod.invoice_to_complete_ex_gst = response.data.invoice_to_complete_ex_gst == "" ? 0 :response.data.invoice_to_complete_ex_gst
                    prod.completion = round(response.data.completion,2)
                    // prod['id'] = response.data.id
                    // prod['isAdd'] = false
                    // prod['isSave'] = false
                    // prod['invoice_to_complete_ex_gst'] = response.data.invoice_to_complete_ex_gst == "" ? 0 :response.data.invoice_to_complete_ex_gst
                    // prod['completion'] = round(response.data.completion,2)
                    // prod['isDisable'] = true
                    // prod['row_id'] = row_id
                }
            })
           
        })
        .catch(function (error) {
            console.log(error);
        });
        await this.addFinalData(WIPData)
        // await this.addColumn(row_id)
    }

    getCurrentWeekAndDays = () => {
        var currentDate = moment();
    
        var weekStart = currentDate.clone().startOf("isoWeek");
        // var weekEnd = currentDate.clone().endOf('isoWeek');
    
        var days = [];
        var weeks = [];
        for (var i = 0; i <= 365; i++) {
          const date = moment(weekStart).add(i, "days").format("MM/DD/YYYY");
          // const date = moment(weekStart, "MM/DD/YYYY").add(i, "days")
          days.push(date);
          weeks.push(moment(date, "MM/DD/YYYY").isoWeek());
        }
    
        const sortedWeekArr = [...new Set(weeks)];
        // console.log("GanttChart ::",days, weeks, newWeekArr.sort(function(a, b) {
        //     return a - b;
        // }))
        return { days, weeks: sortedWeekArr };
      };
    
    render() {
        const {status, items, groups, tasks} = this.state

        const disabledate = current => {
            var current = moment(current).format('DD.MM.YYYY')
            var selectedDate = moment().format('DD.MM.YYYY')
            var startDate = moment(current, "DD.MM.YYYY");
            var endDate = moment(selectedDate, "DD.MM.YYYY");
            var diffDays = endDate.diff(startDate, 'days');
            // console.log('diffDays-->',diffDays)
            let value = diffDays > 0 ? false : true
            return value
        }


        // const disableExistedDates = current => {
        //     var current = moment(current).format('YYYY-MM-DD')
        //     existedQuotesDates.map(i => {
        //         console.log('i,curret--?',i,current)
        //         if(i == current){
        //             return true
        //         }
        //     })
        // }
        console.log('states-->',this.state)
        // console.log('options-->',options)
        // console.log('data-->',data)

        const { days, weeks } = this.getCurrentWeekAndDays();

        function scrollPos(){
            var rightScrollPos = document.getElementById("wipplanner_data_table").scrollTop;
            document.getElementById("gannt_data_table").scrollTop = rightScrollPos;
        }
        
        return (
            <Fragment>
                <div className="for_innerpages">
                <div className="wrapper">
                {/* <!-- Sidebar  --> */}
                <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
               {/* <div class="click-text">working</div> */}
               {/* <div style={{
                    margin: 300,
                    width: 200, height: 200, backgroundColor: 'green'
                    }}

                    // Assigning the ref to div component
                    ref={this.box}>{this.props.children}</div> */}
                {/* <!-- Page Content  --> */}
                <div id="content" className="layoutset">
        
                    <div className="content_board_main_right">
                        <div className="page_header_set">
                            {/* <!-- Header  --> */}
                            <Header></Header>
                            <div className="page_breadcrumbs">
                                <h3 className="title">Home</h3>
                                <div className="breadcrumbs_link">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                          <g id="home" transform="translate(1 1)">
                                            <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>
                                    </div>
                                    <span><Link to='/dashboard'>Home</Link></span><span>-</span><span><Link to="/conveyorTool">Business Conveyor Tool</Link></span><span>-</span><span>WIP Planner Register</span>
                                  
                                </div>
                            </div>
                        </div>
        
                        <div className="section_page_content wip-planner-wrapper">
                            <div className="tab_section">

                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                        <div className="tab_filter">
                                            <h3 className='bold_text'>WIP planner Register</h3>
                                            {/* <div className='static-header mt20'>Data will save automatically</div> */}
                                        </div>
                                        <div className="tab_filter2">
                                    <select className={`select2 select_drop select_control form-control selectSmall ${status == 'Complete' ? 'green' : status == 'In Progress' ? 'yellow' : status == 'On Hold' ? 'red' : status == 'Waiting to Commence' ? 'blue' : 'boldStatus'}`} name="status" id="SelectPeriods" defaultValue={status} value={status} onChange={(e) => this.registerData(e.target.value)}>
                                                                    <option value="" defaultChecked>Status</option>
                                                                    <option value="Complete">Complete</option>
                                                                    <option value="In Progress">In progress</option>
                                                                    <option value="On Hold">On hold</option>
                                                                    <option value="Waiting to Commence">Waiting to Commence</option>
                                                                </select>

                                                          
                                    </div>
                                    { this.state.WIPData.length >= 1 && <div className="planner-register-wrapper">
                                    <div className="box-target_main">
                                        <div className="planner-register-data-wrapper">
                                            <div className="wip-planner-table">
                                            <div className="theme_table table-responsive th_center wipplanner_table" id='wipplanner_data_table' onScroll={scrollPos}>
                                            <table className="table width100" id="quote_table">
                                                <thead>
                                                    <tr>
                                                    <th width="">CLIENT</th>
                                                    <th width="">STATUS</th>
                                                    <th width="">DESCRIPTION</th>
                                                    <th width="">START <span className='smallText'>DATE</span></th>
                                                    <th width="">FINISH DATE <span className='smallText'>(EST)</span></th>
                                                    <th width="">INVOICE TO COMPLETION <span className='smallText'>(EXCL. GST)</span></th>
                                                    <th width="">COMPLETION</th>
                                                    {/* <th>
                                                        <tr>
                                                            {weeks.map((week, i) => {
                                                                return (
                                                                    <th colspan="7" style={{ textAlign: "center" }}>
                                                                        {`Week-${week}`}
                                                                    </th>
                                                                );
                                                            })}
                                                        </tr>
                                                        <tr>
                                                            {days.map((day, i) => {
                                                                return (
                                                                    <th style={{ paddingRight: "2px", paddingLeft: "2px" }}>
                                                                        {day.split("/")[1]}
                                                                    </th>
                                                                );
                                                            })}
                                                        </tr>
                                                    </th>                                                     */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.WIPData.length >= 1 && this.state.WIPData.slice(0).reverse().map((prod,i) => {
                                                        return (
                                                            <tr>
                                                                <td><div class="amount_box"><input  name="client" value={prod.client} disabled={true}/></div></td>
                                                                <td><div class="amount_box"><input class={`${prod.status == 'Complete' ? 'green' : prod.status == 'In Progress' ? 'yellow' : prod.status == 'On Hold' ? 'red' : prod.status == 'Waiting to Commence' ? 'blue' : ''}`} name="status" value={prod.status} disabled={true}/></div></td>
                                                                <td><div class="amount_box"><input  name="description" value={prod.description} disabled={true}/></div></td>
                                                                <td><div class="amount_box"><input  name="start_date" value={moment(prod.start_date).format('DD-MM-YYYY')} disabled={true}/></div></td>
                                                                <td><div class="amount_box"><input  name="finish_date_est" value={moment(prod.finish_date_est).format('DD-MM-YYYY')} disabled={true}/></div></td>
                                                                <td className='aCenter'><div class="amount_box"><input name="invoice_to_complete_ex_gst" value={'$'+ prod.invoice_to_complete_ex_gst} disabled/></div></td>
                                                                <td><div class="amount_box completion-input"><input name="completion" value={prod.completion ? prod.completion + ' %' : ''} disabled/></div></td>
                                                                {/* <td> */}
                                                                    {/* <TimeLine
                                                                        groups={groups}
                                                                        items={items}
                                                                        defaultTimeStart={moment().add(-12, 'hour')}
                                                                        defaultTimeEnd={moment().add(12, 'hour')}
                                                                    /> */}
                                                                  
                                                                {/* </td> */}

                                                                {/* <td style={{display: "flex"}}>

                                                                
                                                                {
                                                                    days.map((day, i) => {
                                                                        const newDate = new Date(day);
                                                                        newDate.setHours(newDate.getHours() + 5);
                                                                        newDate.setMinutes(newDate.getMinutes() + 30);
                                                                        if (
                                                                            newDate >= new Date(prod.task.start) &&
                                                                            newDate <= new Date(prod.task.end)
                                                                        ) {
                                                                            return (
                                                                                    // <td
                                                                                    //     style={{
                                                                                    //         textAlign: "center",
                                                                                    //         verticalAlign: "middle",
                                                                                    //         paddingTop: "4px",
                                                                                    //         paddingBottom: "4px",
                                                                                    //         paddingLeft: "0px",
                                                                                    //         paddingRight: "0px",
                                                                                    //         borderLeft: "0px",
                                                                                    //         borderRight: "0px",
                                                                                    //     }}
                                                                                    // >
                                                                                        <div 
                                                                                            className="progress"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                verticalAlign: "middle",
                                                                                                paddingTop: "5px",
                                                                                                paddingBottom: "5px",
                                                                                                paddingLeft: "12px",
                                                                                                paddingRight: "12px",
                                                                                                borderRadius: "0px",
                                                                                                height:"30px",
                                                                                                background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(13,127,25,1) 40%)"
                                                                                            }}    
                                                                                        >
                                                                                            <div
                                                                                                className="progress-bar"
                                                                                                role="progressbar"
                                                                                                // style={prod.task.styles}
                                                                                                aria-valuenow="100"
                                                                                                aria-valuemin="100"
                                                                                                aria-valuemax="100"
                                                                                            ></div>
                                                                                        </div>                                                                                        
                                                                                    // </td>
                                                                            );
                                                                        } 
                                                                        else {
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                        verticalAlign: "middle",
                                                                                        // padding: "15px 14px",
                                                                                        // margin: "45px 0px",
                                                                                        borderRadius: "0px",
                                                                                        paddingTop: "5px",
                                                                                        paddingBottom: "5px",
                                                                                        paddingLeft: "12px",
                                                                                        paddingRight: "12px",
                                                                                        height:"30px"
                                                                                    }}  
                                                                                >
                                                                                    {i}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })
                                               }
                                                
                                                 
                                                </tbody>
                                            </table>
                                        </div>
                                            </div>
                                        
                                            <div className="wip-planner-chart">
                                               <div className="wip-chart-wrapper">
                                               {/* <Gantt 
                                                    tasks={tasks}
                                                    listCellWidth={""}
                                                    columnWidth={columnWidth}
                                                    headerHeight={82}
                                                    rowHeight={100}
                                                    viewMode="Week"
                                                    // viewDate={moment()}
                                                    // postStepsCount={10}
                                                /> */}
                                                <GanttChart 
                                                    tasks={tasks}
                                                />
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div> }


                                                  
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
        
                 
                </div>
                
                </div>
                
            </Fragment>
          )
    }
}

export default workInProgress;
