import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

class forgetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        email: "",
        emailErrorMessage: "",
        emailRegex : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    }
}

sendLink =  (e) => {
    e.preventDefault()
    if(!this.state.emailErrorMessage && this.state.email !== ""){

  
    console.log('state---->',this.state)

  
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/forget/`, { email: this.state.email })
        .then(res => {
          console.log('res------------>',res)
          window.location.replace(process.env.REACT_APP_FRONTEND_URL);
       
        }).catch((err) => {
            console.log('err->',err)
        })

    }else if(this.state.email == "") {
        this.setState({
            emailErrorMessage: "Please enter email address."
        }) 
    }

}

onClickEmail = (e) => {
    console.log('e.target.value-->',e.target.value,e.target.value.length)
    if(e.target.value == ""){
        this.setState({
            email: e.target.value,
            emailErrorMessage: "Please enter email address."
        }) 
    }else if(this.state.emailRegex.test(e.target.value) === false){
        this.setState({
            email: e.target.value,
            emailErrorMessage: "Please enter valid email address."
        }) 
    }else {
        this.setState({
            email: e.target.value,
            emailErrorMessage: ""
        })
    }
}


  render() {
    const { emailErrorMessage } = this.state
    return (
      <React.Fragment>
          <div className="page">
          
          {/* <!-- header section Start --> */}
          <header className="sticky-header">
              <div className="header-nav">
                  <nav className="navbar navbar-expand-lg">
                      <div className="container">
                          <div className="site-logo">
                              <a href="#">
                                  <img src="assets/images/logo.png"></img>
                              </a>
                          </div>
                          <button class="navbar-toggler toggler_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                              <span class="navbar_toggler_icon navbar_toggler_icon_top"></span>
                              <span class="navbar_toggler_icon navbar_toggler_icon_middel"></span>
                              <span class="navbar_toggler_icon navbar_toggler_icon_bottom"></span>
                          </button>
                          <div className="collapse navbar-collapse" id="navbarToggler">
                              <div className="navbar-close">
                                  <div className="cross-wrap"><span></span><span></span></div>
                              </div>
                              <ul className="navbar-nav ms-auto">
                                  <li className="nav-item">
                                      <Link to='/' className="nav-link">HOME</Link>
                                  </li>
                                  <li className="nav-item">
                                      <Link to='/contactus' className="nav-link">CONTACT US</Link>
                                  </li>
  
                                  <li className="nav-item">
                                      <Link to='/' className="theme_btn">SIGN IN</Link>
                                  </li>
  
                              </ul>
                              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                  <span></span><span></span><span></span>
                              </button>
                          </div>
                      </div>
                  </nav>
              </div>
          </header>
          {/* <!-- header section End --> */}
  
          <div className="inner_container">
               <div className="container p-0">
                   <div className="login_page">
                       <div className="login_left form_sec center-block">
                           <div className="w-100">
                               <h1>Sign In</h1>
                               <p className="mb-2">Back to <b><Link to='/'>Login</Link></b></p>
                               <form onSubmit={this.sendLink}>
                               
                                   <div className="form-group mb-2">
                                   <label>Email</label>
                                                <input type="text" name="" className="form-control" placeholder="Enter your email" value={this.state.email} onChange={(e) => this.onClickEmail(e)}></input>
                                                {emailErrorMessage && (<p className="error"> {emailErrorMessage} </p>)}
                                   </div>
                                   <div className="auth_btn">
                                       <button className="theme_btn">Reset Password</button>
                                   </div>
                               </form>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
          <footer>
              <div className="container">
                  <div className="footer_inner">
                      <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                      <ul>
                          <li>
                              <a href="#">
                                  <img src="assets/images/metro-facebook.svg"></img>
                              </a>
                          </li>
                          <li>
                              <a href="#">
                                  <img src="assets/images/awesome-twitter.svg"></img>
                              </a>
                          </li>
                          <li>
                              <a href="#">
                                  <img src="assets/images/awesome-youtube.svg"></img>
                              </a>
                          </li>
                          <li>
                              <a href="#">
                                  <img src="assets/images/awesome-linkedin.svg"></img>
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </footer>
  
         
          
      </div>
      </React.Fragment>
    )
  }
  
}

export default forgetPassword;
