import React from 'react'
import { Link } from 'react-router-dom'

class register extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            password: ""
        }
    }

    add = (e) => {
        e.preventDefault()
        console.log(this.state)
         // this will update entries with POST
    fetch("${process.env.REACT_APP_BACKEND_URL}/register/", {
        "method": "POST",
        "body": JSON.stringify({
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            email: this.state.email,
            password: this.state.password
        })
        })
        .then(response => response.json())
        .then(response => { 
            console.log('response->',response);
            this.props.history.push('/');
        })
        .catch(err => { console.log('err->',err); });
    }

    render() {
        return (
            <React.Fragment>
                   <div className="page">
         
         {/* <!-- header section Start --> */}
         <header className="sticky-header">
             <div className="header-nav">
                 <nav className="navbar navbar-expand-lg">
                     <div className="container">
                         <div className="site-logo">
                             <a href="#">
                                 <img src="assets/images/logo.png"></img>
                             </a>
                         </div>
                     </div>
                 </nav>
             </div>
         </header>
         {/* <!-- header section End --> */}
         
         <div className="inner_container">
             <div className="container p-0">
                 <div className="login_page">
                     <div className="login_left form_sec">
                         <div className="w-100">
                             <h1>Create Account</h1>
                             <p className="mb-2">Already have an account? <b><Link to='/'>Login</Link></b></p>
                             <form onSubmit={this.add}>
                                 <div className="form-group mb-2">
                                     <label>First Name</label>
                                     <input type="text" name="first_name" className="form-control" placeholder="Enter your first name" value={this.state.firstname} onChange={(e) => this.setState({firstname: e.target.value})}></input>
                                 </div>
                                 <div className="form-group mb-2">
                                     <label>Last Name</label>
                                     <input type="text" name="last_name" className="form-control" placeholder="Enter your last name" value={this.state.lastname} onChange={(e) => this.setState({lastname: e.target.value})}></input>
                                 </div>
                                 <div className="form-group mb-2">
                                     <label>Email*</label>
                                     <input type="text" name="email" className="form-control" placeholder="Enter your email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}></input>
                                 </div>
                                 <div className="form-group mb-2">
                                     <label>Password*</label>
                                     <input type="password" name="password" className="form-control" placeholder="Enter your password" value={this.state.password}  onChange={(e) => this.setState({password: e.target.value})}></input>
                                 </div>
                                 <div className="auth_btn">
                                     <button className="theme_btn">SIGN UP</button>
                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             </div>
         
             <footer>
                 <div className="container">
                     <div className="footer_inner">
                         <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                         <ul>
                             <li>
                                 <a href="#">
                                     <img src="assets/images/metro-facebook.svg"></img>
                                 </a>
                             </li>
                             <li>
                                 <a href="#">
                                     <img src="assets/images/awesome-twitter.svg"></img>
                                 </a>
                             </li>
                             <li>
                                 <a href="#">
                                     <img src="assets/images/awesome-youtube.svg"></img>
                                 </a>
                             </li>
                             <li>
                                 <a href="#">
                                     <img src="assets/images/awesome-linkedin.svg"></img>
                                 </a>
                             </li>
                         </ul>
                     </div>
                 </div>
             </footer>
         
         </div>
         
         </div>
            </React.Fragment>
           )
    }
}
export default register;
