import React from 'react'
import '../../App.css';
import { Link } from 'react-router-dom'
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';
import { map as _map } from 'lodash';
import Footer from '../../components/footer/footer';
class dashboard extends React.Component {
    dashboardModules = () => {
        const moduleArr = [
            {
                title: "KEY NUMBERS",
                image: "./assets/images/number.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/keynumbers",
                class:"box_card_set first",
            },
            {
                title: "BUSINESS CONVEYOR TOOL",
                image: "./assets/images/cash.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/conveyorTool",
                class:"box_card_set third"
            },
        ]
        return moduleArr;
    }

    render() {

        const dashboardModules = this.dashboardModules();

        return (
            <React.Fragment>
                  
            <div className="wrapper">
           {/* <!-- Sidebar  --> */}
           <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
        
            {/* <!-- Page Content  --> */}
            <div id="content" className="layoutset home-dashboard-wrapper">
        
                <div className="content_board_main_right">
                    <div className="page_header_set">
                        {/* <!-- Header  --> */}
                        <Header></Header>
                        <div className="page_breadcrumbs">
                            <h3 className="title">Home</h3>
                            <div className="breadcrumbs_link">
                                <div className="img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                      <g id="home" transform="translate(1 1)">
                                        <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                      </g>
                                    </svg>
                                </div>
                                <span><Link to='/dashboard'>Home</Link></span><span>-</span><span>Dashboard</span>
                            </div>
                        </div>
                    </div>
        
                    <div className="section_page_content home-dashboard-section">
                        <div className="box-target_main">
                            <div className="row">
                                {
                                    _map(dashboardModules, (module, i) => {
                                        return(
                                            <div className="col-xl-4 col-sm-6">
                                                <div className={module.class}>
                                                    <div className="icon_card">
                                                        <span><img src={module.image} alt='module_logo'></img></span>
                                                    </div>
                                                    <h4>{module.title}</h4>
                                                    <p>{module.description}</p>
                                                    <Link to={module.redirectTo}>LET'S GO</Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="box_card_set  first">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/number.svg"></img></span>
                                        </div>
                                        <h4>KEY NUMBERS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <Link to="/keynumbers">LET'S GO</Link>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  second">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/target.svg"></img></span>
                                        </div>
                                        <h4>TARGETS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/targets">LET'S GO</Link></h6>
                                    </div>
                                </div> */}
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="box_card_set  third">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/cash.svg"></img></span>
                                        </div>
                                        <h4>BUSINESS CONVEYOR TOOL</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <Link to="/conveyorTool">LET'S GO</Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
        
                </div>
        
                <Footer />
            </div>
        
            </div>
        
            </React.Fragment>
          )
    }
}

export default dashboard;
