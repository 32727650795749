import React from 'react';
class App extends React.Component {

constructor(props) {
	super(props);

	// Creating a reference
	this.box = React.createRef();
}

componentDidMount() {

	// Adding a click event listener
	document.addEventListener('click', this.handleOutsideClick);
}

handleOutsideClick = (event) => {
    console.log('event------>',event.target,this.box.current,this.props.children)
    console.log('value-->',this.box.current.contains(event.target))
	if (this.box && !this.box.current.contains(event.target)) {
	alert('you just clicked outside of box!');
	}
}

render() {
	return <div style={{
	margin: 300,
	width: 200, height: 200, backgroundColor: 'green'
	}}

	// Assigning the ref to div component
	ref={this.box}>{this.props.children}</div>;
}
}

export default App;
