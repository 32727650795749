import React , { Component, Fragment } from 'react'
import Sidebar from '../Static/sidebar'
import Header from '../Static/header'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import getRefreshtoken from '../Middleware/getRefreshToken'
import moment from 'moment';
import { isEmpty as _isEmpty, omit as _omit, isEqual as _isEqual } from 'lodash';
import Footer from '../../components/footer/footer';

// {
//     date_submitted
//     job_id
//     client
//     description
//     address
//     post_code
//     quotation_ex_gst
//     status
//     quotation_win_date
//     comments
// }


class quotesRegister extends Component {
    constructor(props){
        super(props);
        const staticQuotesData = JSON.parse(localStorage.getItem('defalutQuotesRegister'))
        this.state = {
            quotesData: staticQuotesData,
            existedQuotesDates: [],
            valueAdded: false,
            row_id: '',
            onFocusFieldVal: '',
        }  
    }

    componentDidMount() {
        console.log('didMOunt--------------------->',)
        this.getQuotesRegistrationData()
    }

    getQuotesRegistrationData = async () => {
        await getRefreshtoken()
        const {quotesData, existedQuotesDates} = this.state
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/quotes/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            }
            };
            let finalData = []
        axios(config)
        .then(async (response) => {
            console.log('response----->',response.data)
            let data = response.data
            let todaysData = []
            data.map((prod,i) => {
                console.log('inmap------>',prod,i,prod.date_submitted,moment().format("YYYY-MM-DD"))
                // if(prod.date_submitted == moment().format("YYYY-MM-DD")){
                    console.log('if--------?',)
                    data[i].row_id = i + 1
                    data[i].isAdd = false
                    // data[i].isSave = false
                    data[i].isDisable = true
                    existedQuotesDates.push(prod.date_submitted)
                    todaysData.push(prod)
                // }
            })

            // data.map((prod,i) => {
            //     console.log('remove------>',prod,i)
            //     if(prod.date_submitted !== moment().format("YYYY-MM-DD")){
            //         console.log('remove index',i,i+1)
            //         data.splice(i,i+1)
            //     }
               
            // })
            this.setState({
                existedQuotesDates: existedQuotesDates
            })

            console.log('quotesData-->',quotesData)
            finalData = todaysData.concat(quotesData)
            // data.concat(quotesData)
            console.log('finalData-->',finalData)
            // data.push(quotesData[0])
            // console.log('after push',quotesData)
            this.addFinalData(finalData)
            
            
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    addFinalData = (data) => {
        this.setState({
            quotesData: data
        })
    }

    registerData = async (row_id, name, value) => {
        const {quotesData} = this.state
        console.log('row_id, name, value--------->',row_id,name,value)
        
        await quotesData.map((prod, i) => {
            if(prod.row_id == row_id) {
                console.log('match---------->',prod.row_id, row_id)
                if((name == 'quotation_ex_gst' && /^\d+$/.test(value.substring(1))) || (name == 'quotation_ex_gst' && value.substring(1) == '')){
                    console.log('1---->', value.substring(1), value, value.substring(2))
                    prod[name] = value.charAt(1) == '0' ? value.substring(2) : value.substring(1)
                }else if(name == 'quotation_win_date'){
                    prod[name] = moment(value).format('YYYY-MM-DD')
                }else{
                    prod[name] = name == 'quotation_ex_gst' ? prod[name] : value
                }

            }
        })

        this.setState(({
            quotesData: quotesData,
            row_id
        }))

        if(name === "quotation_win_date"){
            await this.saveData(row_id)
        }
    }

    addStatusValue = async (row_id, name, value) => {

        const {quotesData} = this.state

        quotesData.map((prod,i) => {
            if(prod.row_id == row_id){
                prod[name] = value
            }
        })
        this.setState({
            quotesData: quotesData,
            row_id
        })
    }

    addColumn = async (row_id) => {
       
        const {quotesData} = this.state

        await quotesData.map((prod,i) => {
            console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                prod.isAdd = false
                prod.isDisable = true
            }
        })
        // var indexvalue = row_id + 1
        var indexvalue = quotesData.length + 1

        
        var staticQuote = JSON.parse(localStorage.getItem('defalutQuotesRegister'))
        console.log('row_id-->',row_id,indexvalue,staticQuote[0],quotesData)
        
        staticQuote[0].row_id = indexvalue
        // quotesData[indexvalue] = staticQuote[0]
        console.log('before dta staticQuote--->',staticQuote)
        quotesData.push(staticQuote[0])
       
        
      
        console.log('after dta--->',quotesData)
        // quotesData[row_id].isAdd = false


      
        // console.log('after adding one column',quotesData)



        this.setState({
            quotesData: quotesData,
            valueAdded: false
        })

    }

    deleteData = async(id, index) => {
        const {quotesData} = this.state
        // console.log('delete id---->',id,index,quotesData)
        // var newArr = quotesData


        // newArr.reverse()
        // newArr.splice(index, 1);
      
        // console.log('newArr------>',newArr)
        // console.log('reverse quotes----->',quotesData)
        // quotesData.splice(0,quotesData.length);
        var deleteIndex
        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/quotes/${id}/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            }
            };

        await axios(config)
        .then(async (response) => {
          console.log('response delete -->',response)
         
          await quotesData.map((prod,i) => {
              console.log('---------->',prod.id,id)
              if(prod.id == id){
                  deleteIndex = i 
              }
          })
          console.log('deleteindex---------->',deleteIndex)
          await quotesData.splice(deleteIndex, 1);
          console.log('after delete-->',quotesData)
          await this.addFinalData(quotesData)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    saveData = async () => {
        const {quotesData,existedQuotesDates,valueAdded, row_id} = this.state
        console.log('quotesDta-->',row_id,quotesData, valueAdded)
        await getRefreshtoken()
        // quotesData[index].isAdd = true
        // quotesData[index].isSave = false
        let index
        await quotesData.map((prod,i) => {
            console.log('dataaaaaaaaa--->',prod.row_id,row_id)
            if(prod.row_id == row_id){
                console.log('if--->')
                // prod.isAdd = true
                // prod.isSave = false
                index = i
            }
        })
        // console.log('quotesData--------------------->',quotesData)
        // this.setState({
        //     quotesData:quotesData
        // })
        
        console.log('index--------->',index,this.state.quotesData)
        let data = await quotesData[index]

        // delete data.row_id 
        // delete data.id
        // delete data.isAdd
        // delete data.isSave
        // delete data.days_to_convert
        // delete data.isValueAdded
        // delete data.isDisable

        if(data.quotation_win_date == ""){
            data.quotation_win_date = moment().format('YYYY-MM-DD')
        }
        if(data.quotation_ex_gst == ""){
            data.quotation_ex_gst = 0
        }
        if(data.days_to_convert == ""){
            data.days_to_convert = 0
        }

        console.log('send data------>',data,quotesData)
        if(_isEmpty(data['status'])) data = _omit(data, ['status'])
        
        if(data.id){
            var config = {
                method: 'patch',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/quotes/${data.id}/`,
                data: data
            };
        }else {
            var config = {
                method: 'post',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/quotes/`,
                data: data
            };
            this.setState({
                valueAdded: true
            })
        }

        await axios(config)
        .then(async (response) => {
            console.log('response if----->',response)
            existedQuotesDates.push(response.data.date_submitted)
            quotesData.map((prod,i) => {
                console.log('after response------>',prod.row_id,row_id,index,i)
                if(index == i){
                    console.log('in if---?')
                    // prod['id'] = response.data.id
                    prod.id = response.data.id
                    prod.isValueAdded = data.isValueAdded == false ? true : true 
                    prod.days_to_convert = response.data.days_to_convert
                    // prod['isAdd'] = true
                    // prod['isSave'] = false
                    // prod['days_to_convert'] = response.data.days_to_convert
                    // prod['isDisable'] = true
                    // prod['row_id'] = row_id
                }
            })
           
        })
        .catch(function (error) {
            console.log(error);
        });
        await this.addFinalData(quotesData);
        // await this.addColumn(row_id);
    }

    
    render() {
        const {existedQuotesDates} = this.state
        const disabledate = current => {
            var current = moment(current).format('DD.MM.YYYY')
            var selectedDate = moment().format('DD.MM.YYYY')
            var startDate = moment(current, "DD.MM.YYYY");
            var endDate = moment(selectedDate, "DD.MM.YYYY");
            var diffDays = endDate.diff(startDate, 'days');
            // console.log('diffDays-->',diffDays)
            let value = diffDays > 0 ? false : true
            return value
        }


        const disableExistedDates = current => {
            var current = moment(current).format('YYYY-MM-DD')
            existedQuotesDates.map(i => {
                console.log('i,curret--?',i,current)
                if(i == current){
                    return true
                }
            })
        }
        console.log('states-->',this.state)
        // console.log('options-->',options)
        // console.log('data-->',data)
        return (
            <Fragment>
                <div className="for_innerpages">
                <div className="wrapper">
                {/* <!-- Sidebar  --> */}
                <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
               {/* <div class="click-text">working</div> */}
               {/* <div style={{
                    margin: 300,
                    width: 200, height: 200, backgroundColor: 'green'
                    }}

                    // Assigning the ref to div component
                    ref={this.box}>{this.props.children}</div> */}
                {/* <!-- Page Content  --> */}
                <div id="content" className="layoutset">
        
                    <div className="content_board_main_right">
                        <div className="page_header_set">
                            {/* <!-- Header  --> */}
                            <Header></Header>
                            <div className="page_breadcrumbs">
                                <h3 className="title">Home</h3>
                                <div className="breadcrumbs_link">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                          <g id="home" transform="translate(1 1)">
                                            <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>
                                    </div>
                                    <span><Link to='/dashboard'>Home</Link></span><span>-</span><span><Link to="/conveyorTool">Business Conveyor Tool</Link></span><span>-</span><span>Quotes Register</span>
                                </div>
                            </div>
                        </div>
        
                        <div className="section_page_content common-height-div quote-register-section">
                            <div className="tab_section">

                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                        <div className="tab_filter">
                                            <h3 className='bold_text'>Quotes Register</h3>
                                            {/* <div className='static-header mt20'>Data will save automatically</div> */}
                                        </div>
                                        <div className="theme_table table-responsive th_center quote_table">
                                            <table className="table width100" id="quote_table">
                                                <thead>
                                                    <tr>
                                                        <th>DATE</th>
                                                        <th>JOB ID</th>
                                                        <th>CLIENT</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>ADDRESS</th>
                                                        <th>POST CODE</th>
                                                        <th>QUOTATION $<span className='smallText'>(EX GST)</span></th>
                                                        <th>STATUS</th>
                                                        <th>QUOTATION <span className='smallText'>WIN DATE</span></th>
                                                        <th>DAYS <span className='smallText'>TO CONVERT</span></th>
                                                        <th>COMMENTS</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.quotesData.length >= 1 && this.state.quotesData.slice(0).reverse().map((prod,i) => {
                                                        return (
                                                        <tr>
                                                            <td>
                                                            <div className='tWidth'>{prod.date_submitted ? moment(prod.date_submitted).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}</div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="job_id" 
                                                                        type="text" 
                                                                        value={prod.job_id} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "job_id", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="client" 
                                                                        value={prod.client} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "client", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="description" 
                                                                        value={prod.description} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "description", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="address" 
                                                                        value={prod.address} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "address", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="post_code" 
                                                                        value={prod.post_code} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "post_code", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input 
                                                                        name="quotation_ex_gst" 
                                                                        value={'$'+prod.quotation_ex_gst} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "quotation_ex_gst", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td> 
                                                                <select 
                                                                    className={`select2 select_drop select_control form-control selectSmall ${prod.status == 'Won' ? 'green' : prod.status == 'Lost' ? 'red' : prod.status == 'Pending' ? 'blue' : ''}`} 
                                                                    name="status" 
                                                                    id="SelectPeriods" 
                                                                    defaultValue={prod.status} 
                                                                    value={prod.status} 
                                                                    onChange={(e) => this.addStatusValue(prod.row_id, "status", e.target.value)} 
                                                                    onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                    onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                >
                                                                    <option value="" defaultChecked>Select</option>
                                                                    <option value="Won">Won</option>
                                                                    <option value="Lost">Lost</option>
                                                                    <option value="Pending">Pending</option>
                                                                </select></td>
                                                            <td>
                                                            <DatePicker
                                                                    initialValue={moment().format('DD-MM-YYYY')} 
                                                                    value={moment(prod.quotation_win_date).format('DD-MM-YYYY')} 
                                                                    className="select_box calendar_control"
                                                                    dateFormat="DD-MM-YYYY" 
                                                                    name="quotation_win_date"
                                                                    onChange={date => this.registerData(prod.row_id, "quotation_win_date", date)} 
                                                                    isValidDate={disabledate}
                                                                    timeFormat={false}
                                                                    // inputProps={{readOnly: true, disabled: !prod.isAdd}}
                                                                    closeOnSelect={true}
                                                                    // onKeyDown={(e) => {
                                                                    //     console.log('keydoe---->',e)
                                                                    //     e.preventDefault();
                                                                    // }}
                                                                    readOnly={true}
                                                                    
                                                                />
                                                                
                                                                {/* <div class="amount_box"><input  name="quotation_win_date" value={prod.quotation_win_date} onChange={(e) => this.registerData(prod.id, "quotation_win_date", e.target.value)} /></div> */}
                                                                
                                                                </td>
                                                            <td className='aCenter'><div class="tWidth"></div>{prod.days_to_convert}</td>
                                                            <td>
                                                                <div class="amount_box">
                                                                    <input  
                                                                        name="comments" 
                                                                        value={prod.comments} 
                                                                        onChange={(e) => this.registerData(prod.row_id, "comments", e.target.value)} 
                                                                        onBlur={(e) => !_isEqual(this.state.onFocusFieldVal, e.target.value) && this.saveData()} 
                                                                        onFocus={(e) => this.setState({onFocusFieldVal: e.target.value})}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {prod.isAdd ? <a onClick={() => prod.isValueAdded ? this.addColumn(prod.row_id) : ''}><i class="fa fa-plus add_ic"></i></a> : <a href="#" onClick={() => this.deleteData(prod.id, i)}><i class="far fa-trash-alt delete_ic"></i></a> }
                                                            </td>
                                                        </tr>
                                                        )
                                                   })
                                               }
                                                
                                                 
                                                </tbody>
                                            </table>
                                        </div>            
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer/>  
                </div>
        
                 
                </div>
                
                </div>
                
            </Fragment>
          )
    }
}

export default quotesRegister;
