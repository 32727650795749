import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer_inner">
          <span>
            Copyright © 2022 Business Benchmark Group, All rights reserved.
          </span>
          <ul>
            <li>
              <a href="javascript:void(0);">
                <img src="assets/images/metro-facebook.svg"></img>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <img src="assets/images/awesome-twitter.svg"></img>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <img src="assets/images/awesome-youtube.svg"></img>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <img src="assets/images/awesome-linkedin.svg"></img>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
