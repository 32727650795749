import React, { Component } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DemoData
} from "react-big-scheduler";
import withDragDropContext from "./withDnDContext";

// import "./style.css";


// const data = {
//   behaviors: {getSummaryFunc: undefined, getCustomDateFunc: undefined, getNonAgendaViewBodyCellBgColorFunc: undefined, getScrollSpecialMomentFunc: ƒ, getDateLabelFunc: ƒ, …},
// cellUnit: 0,
// config: {schedulerWidth: '100%', besidesWidth: 20, schedulerMaxHeight: 0, tableHeaderHeight: 40, agendaResourceTableWidth: 160, …}
// documentWidth: 1688
// endDate: "2017-12-23"
// eventGroups: (14) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
// eventGroupsAutoGenerated: true
// events: (15) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
// headers: (7) [{…}, {…}, {…}, {…}, {…}, {…}, {…}]
// isEventPerspective: false
// localeMoment: ƒ hooks()
// renderData: (7) [{…}, {…}, {…}, {…}, {…}, {…}, {…}]
// resizing: false
// resources: (7) [{…}, {…}, {…}, {…}, {…}, {…}, {…}]
// scrollToSpecialMoment: true
// selectDate: "2017-12-18"
// showAgenda: false
// startDate: "2017-12-17"
// viewType: 1
// }

class Basic extends Component {
  constructor(props) {
    super(props);

    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let schedulerData = new SchedulerData("2017-12-18", ViewTypes.Week);
    console.log('schedulerData------>',schedulerData,typeof schedulerData)

    delete schedulerData.eventGroups
    delete schedulerData.renderData

    schedulerData.localeMoment.locale("en");
    schedulerData.setResources(DemoData.resources);
    schedulerData.setEvents(DemoData.events);
    
    
    this.state = {
      viewModel: schedulerData
    };
  }

  render() {
    const { viewModel } = this.state;
    console.log('viewModel-->',viewModel)
    return (
      <div>
        <div>
          <h3 style={{ textAlign: "center" }}>Basic example</h3>
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            viewEventClick={this.ops1}
            viewEventText="Ops 1"
            viewEvent2Text="Ops 2"
            viewEvent2Click={this.ops2}
            onSelecting={false}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}
          />
        </div>
      </div>
    );
  }

  prevClick = schedulerData => {
    schedulerData.prev();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = schedulerData => {
    schedulerData.next();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

 

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

 

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${
        event.title
      }}`
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${
        event.title
      }}`
    );
  };
 

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

export default withDragDropContext(Basic);
