import React from "react";

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <h3>404 | PageNotFound</h3>
    </div>
  );
};

export default PageNotFound;
