import React from 'react'
import '../../App.css';
import { Link } from 'react-router-dom'
import Sidebar from '../Static/sidebar';
import Header from '../Static/header';
import { map as _map } from 'lodash';
import Footer from '../../components/footer/footer';
class dashboard extends React.Component {

    quoteModules = () => {
        const moduleArr = [
            {
                title: "QUOTES REGISTER",
                image: "./assets/images/quote-register.svg",
                // image: "./assets/images/target.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/quotesRegister",
                class:"grey-color",
            },
            {
                title: "WORK IN PROGRESS REGISTER",
                image: "./assets/images/work-in-progress.svg",
                // image: "./assets/images/cash.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/workInProgress",
                class:"light-orange-color"
            },
            {
                title: "WIP PLANNER REGISTER",
                image: "./assets/images/wip-planner-register.svg",
                // image: "./assets/images/number.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/workInPlanner",
                class:"violet-color"
            },
            {
                title: "CALCULATORS",
                image: "./assets/images/number.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/calculators",
                class:"light-pink"
            },
            {
                title: "CASH IN REGISTER",
                // image: "./assets/images/number.svg",
                image: "./assets/images/cash-in-register.svg",
                description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
                redirectTo: "/cashInRegister",
                class:"green-color"
            },
            // {
            //     title: "CASH OUT REGISTER",
            //     // image: "./assets/images/number.svg",
            //     image: "./assets/images/cash-in-register.svg",
            //     description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
            //     redirectTo: "/cashOutRegister",
            //     class:"green-color"
            // },
            // {
            //     title: "CASH FLOW",
            //     image: "./assets/images/cash-flow-register.svg",
            //     // image: "./assets/images/number.svg",
            //     description: "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
            //     redirectTo: "/cashFlow",
            //     class:"violet-color"
            // }
        ]
        return moduleArr;
    }

    render() {
        
        const quoteModules = this.quoteModules();

        return (
            <React.Fragment>
                  
            <div className="wrapper">
           {/* <!-- Sidebar  --> */}
           <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
        
            {/* <!-- Page Content  --> */}
            <div id="content" className="layoutset">
        
                <div className="content_board_main_right">
                    <div className="page_header_set">
                        {/* <!-- Header  --> */}
                        <Header></Header>
                        <div className="page_breadcrumbs">
                            <h3 className="title">Quotes</h3>
                            <div className="breadcrumbs_link">
                                <div className="img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                      <g id="home" transform="translate(1 1)">
                                        <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                      </g>
                                    </svg>
                                </div>
                                <span><Link to='/dashboard'>Home</Link></span><span>-</span><span>Business Conveyor Tool</span>
                            </div>
                        </div>
                    </div>
        
                    <div className="section_page_content quotes-page-wrap">
                        <div className="box-target_main top_box_target">
                            <div className="row">
                                {/* <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  firstQ">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/number.svg"></img></span>
                                        </div>
                                        <h4>DATA VALIDATION</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/dataValidation">LET'S GO</Link></h6>
                                    </div>
                                </div> */}
                                {
                                    _map(quoteModules, (module, i) => {
                                        return(
                                            <div className="col-md-4 col-sm-6">
                                                <div className={`box_card_set  ${module.class} mb-100`}>
                                                    <div className="icon_card">
                                                        <span><img src={module.image}></img></span>
                                                    </div>
                                                    <h4>{module.title}</h4>
                                                    <p>{module.description}</p>
                                                    <Link to={module.redirectTo}>LET'S GO</Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  secondQ">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/target.svg"></img></span>
                                        </div>
                                        <h4>QUOTES REGISTER</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/quotesRegister">LET'S GO</Link></h6>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  thirdQ">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/cash.svg"></img></span>
                                        </div>
                                        <h4>WORK IN PROGRESS REGISTER</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/workInProgress">LET'S GO</Link></h6>
                                    </div>
                                </div> 
                                <div  className="col-md-4 col-sm-6">
                                <div className="box_card_set  first">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/number.svg"></img></span>
                                        </div>
                                        <h4>WIP PLANNER REGISTER</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/workInPlanner">LET'S GO</Link></h6>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        {/* <div className="box-target_main bottom_box_target">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  first">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/number.svg"></img></span>
                                        </div>
                                        <h4>CALCULATORS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/calculators">LET'S GO</Link></h6>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6"> */}
                                    {/* <div className="box_card_set  second">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/target.svg"></img></span>
                                        </div>
                                        <h4>DEBTORS XERO</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/debtorsXero">LET'S GO</Link></h6>
                                    </div> */}
                                {/* </div>
                                <div className="col-md-4 col-sm-6"> */}
                                    {/* <div className="box_card_set  third">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/cash.svg"></img></span>
                                        </div>
                                        <h4>DEBTORS QUICKBOOK</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/debtorsQuickbooks">LET'S GO</Link></h6>
                                    </div> */}
                                {/* </div> */}
                                {/* <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  second">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/target.svg"></img></span>
                                        </div>
                                        <h4>CASH IN REGISTER</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/cashInRegister">LET'S GO</Link></h6>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  third">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/cash.svg"></img></span>
                                        </div>
                                        <h4>CASH OUT REGISTER</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/cashOutRegister">LET'S GO</Link></h6>
                                    </div>
                                </div> */}
                            {/* </div>
                        </div> */}

                        {/* <div className="box-target_main mt20">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  first">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/number.svg"></img></span>
                                        </div>
                                        <h4>KEY NUMBERS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/keynumbers">LET'S GO</Link></h6>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  second">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/target.svg"></img></span>
                                        </div>
                                        <h4>TARGETS</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/targets">LET'S GO</Link></h6>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="box_card_set  third">
                                        <div className="icon_card">
                                            <span><img src="./assets/images/cash.svg"></img></span>
                                        </div>
                                        <h4>QUOTES</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                        <h6><Link to="/conveyorTool">LET'S GO</Link></h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
                <Footer />
            </div>
        
            </div>
        
            </React.Fragment>
          )
    }
}

export default dashboard;
