import React , { Component, Fragment } from 'react'
import Sidebar from '../Static/sidebar'
import Header from '../Static/header'
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import getRefreshtoken from '../Middleware/getRefreshToken'
import FileSaver from 'file-saver'
import FileDownload from 'js-file-download'

import moment from 'moment';
// import required css from library
import OutsideClickHandler from 'react-outside-click-handler';

import { Bar, Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
//   import { Chart } from 'react-chartjs-2'
  import Chart from 'chart.js/auto'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )



const staticValue = JSON.parse(localStorage.getItem('defaultKeyNumbers'))

const staticKeyNumbers = JSON.parse(localStorage.getItem('onlyKeyNumbers'))

class keynumbers extends Component {
    constructor(props){
        super(props);
        // Creating a reference
	    this.box = React.createRef();
        this.state = {
            keynumbers: [],
            defaultKeyNumbers: staticValue,
            onlyKeyNumbers: staticKeyNumbers,
            calDate: "",
            selectedDate: "",
            key_id: "",
            chartKeyNumber: "balance_in_bank",
            weekStartDate: "",
            weekEndDate: "",
            showChart: false,
            labels: [],
            lineLables: [],
            targetset: [],
            actualset: [],
            targetdata: [],
            actualdata: [],
            isDataExist: false,
            exportFile: "",
            showHeader: true,
            showBarChart: true
        }
    }

    componentDidMount() {
        this.currentWeekLastDate()
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevState.showChart && 
            new Date(prevState.weekStartDate) !== new Date(this.state.weekStartDate) && 
            new Date(prevState.weekEndDate) !== new Date(this.state.weekEndDate) &&
            new Date(this.state.weekStartDate) > new Date(this.state.weekEndDate))
                this.setState({showChart: false})
    }

    currentWeekLastDate = async () => {
        if(this.state.selectedDate == ""){
            var lastday = moment().endOf('week').toDate();
            var formated = moment(lastday).format('YYYY-MM-DD')
            var finaldate = moment(moment(formated).add(1, 'd').format('YYYY-MM-DD'))
            var enddate = moment(finaldate).format('YYYY-MM-DD')
    
            var firstday = moment().startOf('week').toDate();
            var formateddate = moment(firstday).format('YYYY-MM-DD')
            var startdate = moment(moment(formateddate).add(1, 'd').format('YYYY-MM-DD'))
            var startdate = moment(startdate).format('YYYY-MM-DD')
    
            await this.saveDate(startdate, enddate)
            await this.getKeyNumbers(enddate)
            await this.getTragets(enddate)
        }
    }

    saveDate = (startdate,enddate) => {
        var formated = new Date(enddate)
        this.setState({
            selectedDate: enddate,
            calDate: formated,
            weekStartDate: startdate,
            weekEndDate: enddate
        })
    }

    selectDate = async (date) => {
        await this.setState({
            selectedDate: moment(date).format('YYYY-MM-DD'),
            calDate: date,
            defaultKeyNumbers: JSON.parse(localStorage.getItem('defaultKeyNumbers')),
            onlyKeyNumbers: JSON.parse(localStorage.getItem('onlyKeyNumbers')),
            keynumbers: [],
            key_id: "",
            isDataExist: false
        })
        var formeted = moment(date).format('YYYY-MM-DD')
        await this.getTragets(formeted)
        await this.getKeyNumbers(formeted)
        
    }

    disabledDate = (current) => {
          return moment(current).day() === 0 
    }

    getTragets = async (date) => {
        const { defaultKeyNumbers } = this.state
        await getRefreshtoken()
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/numbersget/target/`,
        headers: { 
            'Authorization': 'Bearer '+localStorage.getItem('access')
        },
        data: {
            "date": date
            }
        };

        axios(config)
        .then(async (response) => {
            if(response.data && response.data['id']){
                delete response.data['id']
                delete response.data['date']
                delete response.data['created_at']
                delete response.data['updated_at']

                const targets = response.data

                for(let x in targets){
                    await defaultKeyNumbers.map((prod,i) => {
                        if(prod.name == x){
                            prod.target = targets[x]
                        }
                    })
                }
                await this.finalKeynumbers(defaultKeyNumbers)
            }else {
                await this.finalKeynumbers(defaultKeyNumbers)
            }
            
        }).catch(async (error) => {
            await this.finalKeynumbers(defaultKeyNumbers)
        });


    }

    getKeyNumbers = async (date) => {
        const { defaultKeyNumbers, onlyKeyNumbers } = this.state
        await getRefreshtoken()
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/numbersget/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            },
            data: {
                "date": date
                }
            };

            axios(config)
            .then(async (response) => {
                if(response.data && response.data['id']){
                    this.setState({
                        key_id: response.data['id'],
                        isDataExist: true
                    })

                    delete response.data['id']
                    delete response.data['date']
                    delete response.data['created_at']
                    delete response.data['updated_at']

                    const keynumbers = response.data
                    
                    for(let x in keynumbers){
                    
                        await defaultKeyNumbers.map((prod,i) => {
                            if(prod.name == x){
                                prod.actualValue = keynumbers[x]
                                prod.variance = keynumbers[x] !== 0 ? prod.target == null ? 0 - keynumbers[x] : prod.target - keynumbers[x] : ''
                            }
                        })

                        for(let x1 in onlyKeyNumbers){
                           if(x1 == x){
                             onlyKeyNumbers[x1] = keynumbers[x]
                           }
                        }
                    }

                    this.setState({
                        defaultKeyNumbers: defaultKeyNumbers,
                        onlyKeyNumbers: onlyKeyNumbers
                    })
                   
                    await this.finalKeynumbers(defaultKeyNumbers)
                }else{
                    await this.finalKeynumbers(defaultKeyNumbers)
                }
            })
               
            .catch(function (error) {
                console.log(error);
            });
    }

    finalKeynumbers = (values) => {
        this.setState({
            keynumbers: values
        })
    }

    addActualValue = async (name, value, actualValue, target) => {

        const {keynumbers, isDataExist, onlyKeyNumbers} = this.state

        const fetchAutoCalculateData = ['balance_in_bank','debtors','creditors','work_in_progress','quotes_value_for_the_week','cost_of_goods_sold_for_the_week','total_employment_costs_for_the_week','revenue_for_the_week','number_of_invoices_for_the_week']

        if(target == null){
            target = 0
        }
        keynumbers.map((prod,i) => {
            if(prod.name == name){
                prod.actualValue = value
                prod.variance = name == 'tax_obligations_up_to_date_yes_or_no' ? '' : target - value
            }
        })
        this.setState({
            keynumbers: keynumbers
        })

        var lastday = moment().endOf('week').toDate();
        var formated = moment(lastday).format('YYYY-MM-DD')
        var finaldate1 = moment(moment(formated).add(1, 'd').format('YYYY-MM-DD'))
        var finaldate = moment(finaldate1).format('YYYY-MM-DD')

        const data = onlyKeyNumbers

        data["date"] = this.state.selectedDate == "" ? finaldate : this.state.selectedDate
        data["created_by"] = parseInt(localStorage.getItem('user_id')) 
        data[name] = name == 'tax_obligations_up_to_date_yes_or_no' ? value : parseInt(value)

        await getRefreshtoken();
        if(isDataExist){
            var config = {
                    method: 'patch',
                    headers: { 
                        'Authorization': 'Bearer '+localStorage.getItem('access')
                    },
                    url: `${process.env.REACT_APP_BACKEND_URL}/key_numbers/${this.state.key_id}/`,
                    data: data
                };
                axios(config)
                .then(async (response) => {
                   if(fetchAutoCalculateData.includes(name)){
                        this.fetchAutoCalcData()
                   }
                })
                .catch(function (error) {
                    console.log(error);
                });
    
        }else{
            var config = {
                method: 'post',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/key_numbers/`,
                data: data
                };
            axios(config)
            .then(async (response) => {

                if(response.data && response.data['id']){
                    this.setState({
                        key_id: response.data['id'],
                        isDataExist: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    fetchAutoCalcData = async () => {
        // await getRefreshtoken()
        const {selectedDate, keynumbers} = this.state
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/numbersget/`,
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            },
            data: {
                "date": selectedDate
                }
            };
        
        axios(config)
        .then(async (response) => {

            const autoCalcValue = ['average_invoice_for_the_week','quotes_value_for_the_month','cost_of_goods_sold_month_to_date','revenue_month_to_date','gross_profit_for_the_month_to_date','utilization_average_for_the_week','break_even_number_or_monthly','total_employment_costs_month_to_date', 'total_employment_costs_vs_revenue_ratio', 'liquidity_ratio']

            for(let x in response.data){
                   
                if(autoCalcValue.includes(x) && response.data[x] !== 0){
                    console.log('-->?',autoCalcValue.includes(x), response.data[x])
                    keynumbers.map((prod,i) => {
                        if(prod.name == x){
                            prod.actualValue = response.data[x]
                            prod.variance = x == 'tax_obligations_up_to_date_yes_or_no' ? '' : response.data[x] !== 0 ? prod.target == null ? 0 - response.data[x] : prod.target - response.data[x] : ''
                        }
                    })
                }

            }

            this.finalKeynumbers(keynumbers)

            
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    selectKeyNumbers = (e) => {
        this.setState({
            chartKeyNumber: e.target.value
        })
    }

    setStartDate = (date) => {
        this.setState({
            weekStartDate: moment(date).format('YYYY-MM-DD'),
        })
    }

    setEndDate = (date) => {
        this.setState({
            weekEndDate: moment(date).format('YYYY-MM-DD'),
        })
    }

    buildChart = async (startdate,enddate) => {
        const { weekStartDate, weekEndDate, chartKeyNumber} = this.state
        var data = {
            id: localStorage.getItem('user_id'),
            filter_data: chartKeyNumber 
        }
        data['start date'] = weekStartDate == "" ? startdate : weekStartDate
        data['end date'] = weekEndDate == "" ? enddate : weekEndDate

        var label= [], targetset=[], actualset=[]
        var lineLable = [], actualdata=[], targetdata=[]
   
        // lineLable.push(moment(data['start date']).format('DD-MM-YYYY'))
        await getRefreshtoken()
        var config = {
            method: 'post',
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            },
            url: `${process.env.REACT_APP_BACKEND_URL}/numbersget/chart_export/`,
            data: data
        };

        await axios(config)
        .then(async (response) => {
            var data = response.data
            if(data){
                await data.map((prod,i) => {
                    label.push(moment(prod.date).format('DD-MM-YYYY'))
                    lineLable.push(moment(prod.date).format('DD-MM-YYYY'))
                    targetset.push(prod.target)
                    actualset.push(prod.actual)
                    targetdata.push(prod.target)
                    actualdata.push(prod.actual)
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        }); 

        if(actualdata.length == 1){
            actualdata.splice(0,0,0)
        }
        if(targetdata.length == 1){
            targetdata.splice(0,0,0)
        }

        // if(label.length == 1){
        //     label.push('','')
        //     // lineLable.push('','')
        // }else if(label.length == 2){
        //     label.push('')
        //     // lineLable.push('')
        // }

       

        this.setState({
            showChart: true,
            labels: label,
            lineLables: lineLable,
            targetset: targetset,
            actualset: actualset,
            targetdata: targetdata,
            actualdata: actualdata
        })
    }

    exportChart = async (startdate,enddate) => {
        const { weekStartDate, weekEndDate, chartKeyNumber} = this.state
        const data = {
            filter_data: chartKeyNumber 
        }
        data['start date'] = weekStartDate == "" ? startdate : weekStartDate
        data['end date'] = weekEndDate == "" ? enddate : weekEndDate
        await getRefreshtoken()
        var config = {
            method: 'post',
            headers: { 
                'Authorization': 'Bearer '+localStorage.getItem('access')
            },
            url: `${process.env.REACT_APP_BACKEND_URL}/numbersget/download_chart_export/`,
            data: data,
            responseType: 'arraybuffer'
        };

        var sd = weekStartDate == "" ? startdate : weekStartDate
        var ed = weekEndDate == "" ? enddate : weekEndDate

        axios(config)
        .then(async (response) => {
          
            // this.download(response.data, 'myfilename.xlsx', 'text/plain')
            // this.startDownload(response.data,"resobyte.xlsx")
            const blob = new Blob([response.data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
      
            FileDownload(blob, `Chart-${chartKeyNumber}-${localStorage.getItem('user_name')}-${data['start date']}to${data['end date']}.xlsx`);
            this.setState({
                exportFile: response.data
            })
            
        })
        .catch(function (error) {
            console.log(error);
        }); 
    }

  

 startDownload = (file, fileName) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
   
}


    download(text, name, type) {
        let blob = new Blob([text], {type: 'application/ms-excel'});
        FileSaver.saveAs(blob, "fileName.xlsx");
      }

    tabChange = (value) => {
        this.setState({
            showHeader: value
        })
    }

    chartType = (value) => {
        this.setState({
            showBarChart: value
        })
    }
    
    render() {
        const disableWeekends = current => {
            return current.day() == 0
        }
    
        var firstday = moment().startOf('week').toDate();
        var formateddate = moment(firstday).format('YYYY-MM-DD')
        var startdate = moment(moment(formateddate).format('YYYY-MM-DD'))
        var startdate = moment(startdate).format('YYYY-MM-DD')

        var lastday = moment().endOf('week').toDate();
        var formated = moment(lastday).format('YYYY-MM-DD')
        var finaldate1 = moment(moment(formated).add(1, 'd').format('YYYY-MM-DD'))
        var finaldate = moment(finaldate1).format('YYYY-MM-DD')
     
            const options = {
                maintainAspectRatio : false,
                plugins: {
                  // title: {
                  //   display: true,
                  //   text: 'Keynumbers chart',
                  // },
                  legend: {
                    display: true,
                  //   labels: {
                  //     boxWidth: 50,
                  //     fontSize: 25,
                  //     fontColor: "gray",
                  //   },
                    position: "top",
                  },
                },
                //responsive: true,
                scales: {
                //   x: {
                //     stacked: true,
                //   },
                //   y: {
                //     stacked: true,
                //   },
                },
              };
              
            const labels = this.state.showChart && this.state.showBarChart ? this.state.labels : this.state.lineLables ;
              
             const data = {
                labels,
                datasets: [
                  {
                    label: 'Actual',
                    data: this.state.actualset,
                    backgroundColor: 'rgb(255, 99, 132)',
                  },
                  {
                    label: 'Target',
                    data: this.state.targetset,
                    backgroundColor: 'rgb(75, 192, 192)',
                  }
                ],
              };
            
          const lineLables = this.state.lineLables
      
           const lineData =  {
            labels,
            datasets: [
              {
                label: "Actual",
                data: this.state.actualdata,
                fill: true,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgb(255, 99, 132)"
              },
              {
                label: 'Target',
                data: this.state.targetdata,
                fill: true,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgb(75, 192, 192)"
              }
            ]
          };
      
        const disabledFields = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        
        return (
            <Fragment>
                <div className="for_innerpages">
                <div className="wrapper">
                {/* <!-- Sidebar  --> */}
                <Sidebar handleSidebar={this.props.handleSidebar} openSidebar={this.props.openSidebar}></Sidebar>
          
                {/* <!-- Page Content  --> */}
                <div id="content" className="layoutset">
        
                    <div className="content_board_main_right">
                        <div className="page_header_set">
                            {/* <!-- Header  --> */}
                            <Header></Header>
                            <div className="page_breadcrumbs">
                                <h3 className="title">Home</h3>
                                <div className="breadcrumbs_link">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.304" height="30.115" viewBox="0 0 27.304 30.115">
                                          <g id="home" transform="translate(1 1)">
                                            <path id="Path_435" data-name="Path 435" d="M3,11.84,15.652,2,28.3,11.84V27.3a2.812,2.812,0,0,1-2.812,2.812H5.812A2.812,2.812,0,0,1,3,27.3Z" transform="translate(-3 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_436" data-name="Path 436" d="M9,26.058V12h8.435V26.058" transform="translate(-0.565 2.058)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>
                                    </div>
                                    <span><Link to='/dashboard'>Home</Link></span><span>-</span><span>Keynumbers</span><span>-</span><span>Data</span>
                                </div>
                            </div>
                        </div>
        
                        <div className="section_page_content keynumbers-data-wrapper">
                            <div className="calculator-keynumber-data">
                                <div className="tab_section">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                      <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data" type="button" role="tab" aria-controls="data" aria-selected="true" onClick={() => this.tabChange(true)}>Data</button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="chart-tab" data-bs-toggle="tab" data-bs-target="#chart" type="button" role="tab" aria-controls="chart" aria-selected="false" onClick={() => this.tabChange(false)}>Charts & Export</button>
                                      </li>
                                      <li className="keynumber-info">
                                      {this.state.showHeader && <div className='static-header'>Data will save automatically</div> }
                                      </li>
                                    </ul>

                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-tab">
                                            <div className="tab_filter">
                                                <h3>Capture your actual numbers for the week</h3>
                                               
                                                <div class="actoin_sec select-week-date">
                                                    <label>Select Week end Date:</label>
                                                    <DatePicker
                                                        initialValue={this.state.calDate == "" ? new Date(finaldate) : this.state.calDate} 
                                                        className="select_box calendar_control"
                                                        dateFormat="DD/MM/YYYY" 
                                                        onChange={date => this.selectDate(date)} 
                                                        isValidDate={disableWeekends}
                                                        timeFormat={false}
                                                        inputProps={{readOnly: true}}
                                                        closeOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="theme_table table-responsive">
                                                <table className="table dt-responsive nowrap width100" id="data_table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>KEYNUMBERS</th>
                                                            <th width='10%'>TARGET</th>
                                                            <th className="text-center">ACTUAL</th>
                                                            <th>VARIANCE</th>
                                                            <th>DESCRIPTION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                 
                                                    {
                                                        this.state.keynumbers.length >= 1 && this.state.keynumbers.map((prod,i) => {
                                                            return (
                                                                <tr>
                                                                    <td className="bold_val">{prod.id}</td>
                                                                    <td className="bold_val">{prod.key}</td>
                                                                    <td className=""><div className="amount_box with_dollar"><p>{prod.ispercent ? '%' : prod.notarget ? '' : '$'} {prod.target}</p></div></td>
                                                                    {
                                                                        !disabledFields.includes(i+1) ? (
                                                                            <td className="text-center"><div className="amount_box with_dollar"><span>{prod.ispercent ? '%' : prod.notarget ? '' : '$'}</span>
                                                                                {prod.name == 'tax_obligations_up_to_date_yes_or_no' ? 
                                                                                <select className="select2 select_drop select_control" id="SelectPeriods" defaultValue={prod.actualValue == null ? 'yes' : prod.actualValue} onChange={(e) => this.addActualValue(prod.name, e.target.value, prod.actualValue, prod.target)}>
                                                                                    <option value="Yes">yes</option>
                                                                                    <option value="No">no</option>
                                                                                    <option value="Payment Plan">payment plan</option>
                                                                                </select> : 
                                                                                <input type="number" name="actual" onChange={(e) => this.addActualValue(prod.name, e.target.value, prod.actualValue, prod.target)} value={prod.actualValue == 0 ? '' : prod.actualValue}></input>
                                                                                }
                                                                            </div></td>
                                                                        ) : (
                                                                            <td className=""><div className="amount_box with_dollar"><p>{prod.ispercent ? '%' : prod.notarget ? '' : '$'} {prod.actualValue == 0 ? '' : prod.actualValue}</p></div></td>
                                                                        )
                                                                    }
                                                                    <td className="text-center">
                                                                        <div className={`amount_box with_dollar ${prod.target > prod.actualValue ? prod.lessThenTarget : prod.moreThenTarget }`}>
                                                                            <p>
                                                                                {prod.ispercent ? '% ' : prod.notarget ? '' : '$ '}
                                                                                {prod.redAndMinus ? prod.isMirror ? prod.target < prod.actualValue ? '-' : '' : prod.target > prod.actualValue ? '-' : '' : prod.greenAndMinus ? prod.isMirror ? prod.target < prod.actualValue ? '-' : '' : prod.target > prod.actualValue ? '-' : '' : ''}
                                                                                {prod.name == 'tax_obligations_up_to_date_yes_or_no' ? '' : Math.abs(prod.variance)}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    <td>{prod.description1}</td>
                                                                </tr>
                                                            )
                                                       })
                                                   }
                                                     
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade visualize-key-number" id="chart" role="tabpanel" aria-labelledby="chart-tab">
                                            <div className="tab_filter">
                                                <h3>Visualize your Key Numbers</h3>
                                                <div className="actoin_sec">
                                                <a href="" id="a"></a>
                                                    <button onClick={() => this.exportChart(startdate,finaldate)}>
                                                        Export to Excel</button>
                                                </div>
                                            </div>
                                            <div className="tab_form">
                                                <div className="row">
                                                    <div className="col-xl-2 col-md-6 select_parent select_box common-field-sec">
                                                        <label>Select data to chart:</label>
                                                        <select className="form-control select_control" id="select_date" defaultValue={this.state.chartKeyNumber} onChange={(e) => this.selectKeyNumbers(e)}>
                                                            {staticValue.length >= 1 && staticValue.map((prod,i) => {
                                                                 return (
                                                                    <option value={prod.name} >{prod.key}</option>
                                                                 )
                                                            })}
                                                            
                                                        </select>
                                                    </div>
                                                    <div className="col-xl-2 col-md-6 select_parent select_box common-field-sec">
                                                        <label>Select Periods:</label>
                                                        <input name='weekly'  className='form-control' value="Weekly" disabled></input>
                                                        {/* <select className="select2 select_drop" id="SelectPeriods" disabled>
                                                            <option>Weekly</option>
                                                        </select> */}
                                                    </div>
                                                    <div className="col-xl-2 col-md-6 select_parent common-field-sec">
                                                        <label>Week Start:</label>
                                                        <DatePicker 
                                                            initialValue={this.state.weekStartDate == "" ? new Date(startdate) : new Date(this.state.weekStartDate)}  
                                                            dateFormat="DD/MM/YYYY" 
                                                            className="select_box calendar_control"
                                                            onChange={date => this.setStartDate(date)}
                                                            isValidDate={disableWeekends}
                                                            timeFormat={false}
                                                            inputProps={{readOnly: true}}
                                                            closeOnSelect={true}
                                                        />
                                                    </div>
                                                    <div className="col-xl-2 col-md-6 select_parent common-field-sec">
                                                        <label>Week End:</label>
                                                        <DatePicker 
                                                            initialValue={this.state.weekEndDate == "" ? new Date(finaldate) : new Date(this.state.weekEndDate)}
                                                            dateFormat="DD/MM/YYYY" 
                                                            className="select_box calendar_control"
                                                            onChange={date => this.setEndDate(date)} 
                                                            isValidDate={disableWeekends}
                                                            timeFormat={false}
                                                            inputProps={{readOnly: true}}
                                                            closeOnSelect={true}
                                                        />  
                                                    </div>
                                                    <div className="col-xl-2 col-12 common-field-sec build-chart-btn">
                                                        <button className="pink_btn" disabled={new Date(this.state.weekStartDate) > new Date(this.state.weekEndDate)}  onClick={() => this.buildChart(startdate,finaldate)}>BUILD CHART</button>
                                                        
                                                    </div>
                                                    { this.state.showChart ? <div className="col-xl-2 col-12 common-field-sec chart-options">
                                                        <div className="chart-options-div">
                                                            <a href='javascript:void(0);' onClick={() => this.chartType(true)}>Bar</a>
                                                            <span>&nbsp; | &nbsp; </span>
                                                            <a href='javascript:void(0);' onClick={() => this.chartType(false)}>Line </a> 
                                                    </div></div> : '' }
                                                    {new Date(this.state.weekStartDate) > new Date(this.state.weekEndDate) && (<p className="error"> Week End must be greater than Week Start. </p>)}
                                                    <div className="col-12 keynumber-chart">
                                                        {this.state.showChart ? this.state.showBarChart ?
                                                        <Bar options={options} data={data} />
                                                        : <Line options={options} data={lineData} /> : ''}
                                                    </div>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                      <footer>
                        <div className="container">
                            <div className="footer_inner">
                                <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/metro-facebook.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-twitter.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-youtube.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <img src="assets/images/awesome-linkedin.svg"></img>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </footer>     
                </div>
        
                 
                </div>
                
                </div>
                
            </Fragment>
          )
    }
}

export default keynumbers;
