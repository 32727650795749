import moment from "moment";
import React from "react";

const GanttChart = (props) => {
  const getCurrentWeekAndDays = () => {
    var currentDate = moment();

    var weekStart = currentDate.clone().startOf("isoWeek");
    // var weekEnd = currentDate.clone().endOf('isoWeek');

    var days = [];
    var weeks = [];
    for (var i = 0; i <= 365; i++) {
      const date = moment(weekStart).add(i, "days").format("MM/DD/YYYY");
      // const date = moment(weekStart, "MM/DD/YYYY").add(i, "days")
      days.push(date);
      weeks.push(moment(date, "MM/DD/YYYY").isoWeek());
    }

    const sortedWeekArr = [...new Set(weeks)];
    // console.log("GanttChart ::",days, weeks, newWeekArr.sort(function(a, b) {
    //     return a - b;
    // }))
    return { days, weeks: sortedWeekArr };
  };
  const { days, weeks } = getCurrentWeekAndDays();
  // console.log("check days and weeks ::", days, weeks);
  // console.log("check date diff ::", moment().format('DD/MM/YYYY') > moment('29/06/2023', 'DD/MM/YYYY').format('DD/MM/YYYY'))
  // console.log("date checking ::", new Date(), new Date("29/06/2023"));
  
  function scrollPos(){
    var rightScrollPos = document.getElementById("gannt_data_table").scrollTop;
    document.getElementById("wipplanner_data_table").scrollTop = rightScrollPos;
  }

  return (
    <div className="wip-planner-gantt-chart table-responsive" id="gannt_data_table" onScroll={scrollPos} style={{ paddingLeft: "0px" }}>
      <table
        id="example1"
        style={{ marginBottom: "0px" }}
        className=" table table-bordered table-striped "
      >
        <thead>
          <tr>
            {weeks.map((week, i) => {
              return (
                <th colspan="7" style={{ textAlign: "center" }}>
                  {`Week-${week}`}
                </th>
              );
            })}
          </tr>
          <tr>
            {days.map((day, i) => {
              return (
                <th style={{ paddingRight: "2px", paddingLeft: "2px" }}>
                  {day.split("/")[1]}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.tasks
            .slice(0)
            .reverse()
            .map((task, i) => {
              // console.log("check tasks ::", task);
              return (
                <tr>
                  {days.map((day, i) => {
                    // console.log("compare date ::", new Date(day), new Date(task.start), new Date(task.end))
                    const newDate = new Date(day);
                    newDate.setHours(newDate.getHours() + 5);
                    newDate.setMinutes(newDate.getMinutes() + 30);
                    if (
                      newDate >= new Date(task.start) &&
                      newDate <= new Date(task.end)
                    ) {
                      // console.log("in ifff ::", task, new Date(day), new Date(task.start));
                      return (
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            borderLeft: "0px",
                            borderRight: "0px",
                          }}
                        >
                          <div className="progress">
                            <div
                              className="progress-bar gantt{{data.id}}"
                              role="progressbar"
                              style={task.styles}
                              aria-valuenow="100"
                              aria-valuemin="100"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td></td>
                      );
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default GanttChart;
