import React from "react";
import axios from 'axios'


class Header extends React.Component {

    logout = async (e) => {
        e.preventDefault()
        console.log('localstorage---->',localStorage.getItem('refresh'))

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/logout/`, { refresh: localStorage.getItem('refresh') })
            .then(res => {
              console.log('res------------>',res)
              localStorage.clear()
              window.location.replace(process.env.REACT_APP_FRONTEND_URL);

            }).catch((err) => {
                console.log('err->',err)
            })
    }

    render() {
        return (
            <div className="page_header_set_top">
                <div className="searc_barcls">
                    <input type="text" className="searcharea" placeholder="Search"></input>
                </div>
                <div className="dropdown">
                <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="profile_img">
                        <img src="assets/images/profile.png"></img>
                    </div>
                    {localStorage.getItem('user_name')}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><button className="dropdown-item" onClick={this.logout}>Logout</button></li>
                </ul>
                <button type="button" class="btn toggle_btn" data-bs-toggle="collapse" data-bs-target="#sidebar" >   
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" class="toggle_line">
                        <g id="bar-chart-2" transform="translate(1 13) rotate(-90)">
                        <line id="Line_1" data-name="Line 1" y1="10" transform="translate(12 6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <line id="Line_2" data-name="Line 2" y1="16" transform="translate(6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <line id="Line_3" data-name="Line 3" y1="6" transform="translate(0 10)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>  


                    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="toggle_close">
                        <path d="M1 2.14285L2.1428 1L11 9.85715L19.8572 1L21 2.14285L12.1428 11L21 19.8572L19.8572 21L11 12.1428L2.1428 21L1 19.8572L9.85715 11L1 2.14285Z" fill="white" stroke="white"/>
                    </svg>
                </button>
                </div>
            </div>
        )
    }
}

export default Header;