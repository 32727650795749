import React from 'react'
import '../../App.css'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import moment from 'moment'
import axios from 'axios'
// import toast from 'react-toastify'

const staticValue = [
    {   
        "id": "1",
        "key": "Balance in Bank",
        "name": "balance_in_bank",
        "description": "Cash in the bank. Known as BIB",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "isMinus": true,
        "redAndMinus": true,
    },
    {
        "id": "2",
        "key": "Debtors",
        "name": "debtors",
        "description": "Customers who owe you money.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "isMinus": true,
        "redAndMinus": true,
        "isMirror": true
    },
    {
        "id": "3",
        "key": "Creditors",
        "name": "creditors",
        "description": "Suppliers you owe money to.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "isMinus": true,
        "redAndMinus": true,
        "isMirror": true
    },
    {
        "id": "4",
        "key": "Work In Progress",
        "name": "work_in_progress",
        "description": "Value of work you have agreed to do which has not yet been delivered",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {
        "id": "5",
        "key": "Quotes $ value for the week",
        "name": "quotes_value_for_the_week",
        "description": "The value of the quotes you sent out this week.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {   
        "id": "6",
        "key": "Cost of Goods Sold for the week",
        "name": "cost_of_goods_sold_for_the_week",
        "description": "What are the variable costs when you do a sale, e.g. sales staff. Known as COGS",
        "target": null,
        "actualValue": null,
        "variance": "",
        // "isMinus": true,
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "greenAndMinus": true
    },
    {
        "id": "7",
        "key": "Total Employment Costs for the week",
        "name": "total_employment_costs_for_the_week",
        "description": "The total costs of employing people this week. All inclusive.",
        "target": null,
        "actualValue": null,
        "variance": "",
        // "isMinus": true,
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "greenAndMinus": true
    },
    {
        "id": "8",
        "key": "Revenue for the week",
        "name": "revenue_for_the_week",
        "description": "The amount that was invoiced/sold this week?",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {
        "id": "9",
        "key": "Number of invoices for the week",
        "name": "number_of_invoices_for_the_week",
        "description": "How many invoices/receipts for the week",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "notarget": true,
        "redAndMinus": true,
    },
    {
        "id": "10",
        "key": "Average $ Invoice for the week",
        "name": "average_invoice_for_the_week",
        "description": "Revenue for week (actual) / Number Invoices (actual)",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {   
        "id": "11",
        "key": "Quotes $ value for the month",
        "name": "quotes_value_for_the_month",
        "description": "Cash in the bank. Known as BIB",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "isMinus": true,
        "redAndMinus": true,
    },
    {
        "id": "12",
        "key": "Cost of Goods Sold month to date",
        "name": "cost_of_goods_sold_month_to_date",
        "description": "Calc: Sum of all of the 6. COGS for the week in this month.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "isMinus": true,
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "greenAndMinus": true
    },
    {
        "id": "13",
        "key": "Revenue month to date",
        "name": "revenue_month_to_date",
        "description": "Sum of all of the sales for the week in this month.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "isMinus": true,
        "redAndMinus": true,
    },
    {
        "id": "14",
        "key": "Gross Profit % for the month to date",
        "name": "gross_profit_for_the_month_to_date",
        "description": "Actual COGS (month to date) / Actual sales for month",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "ispercent": true
    },
    {
        "id": "15",
        "key": "Utilization % average for the week.",
        "name": "utilization_average_for_the_week",
        "description": "Actual sales for week / Target Utilisation as a %",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "ispercent": true
    },
    {   
        "id": "16",
        "key": "Break Even Number/Monthly.*",
        "name": "break_even_number_or_monthly",
        "description": "",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {
        "id": "17",
        "key": "Total Employment Costs month to date",
        "name": "total_employment_costs_month_to_date",
        "description": "This your staff costs for the month to date. Enter your monthly staff costs.",
        "target": null,
        "actualValue": null,
        "variance": "",
        // "isMinus": true,
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "redAndMinus": true,
        "isMirror": true
    },
    {
        "id": "18",
        "key": "Total Employment Costs vs. Revenue ratio",
        "name": "total_employment_costs_vs_revenue_ratio",
        "description": "Actual total employment costs / Actual sales for month",
        "target": null,
        "actualValue": null,
        "variance": "",
        // "isMinus": true,
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "ispercent": true,
        "redAndMinus": true,
    },
    {
        "id": "19",
        "key": "Liquidity % Ratio",
        "name": "liquidity_ratio",
        "description": "(Actual BIB + Actual Debtors) / Actual Creditors.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "ispercent": true,
        "redAndMinus": true,
    },
    {
        "id": "20",
        "key": "Actual Banked/Cash In for the week",
        "name": "actual_banked_or_cash_in_for_the_week",
        "description": "How much you banked as cash this week",
        "target": null,
        "actualValue": null,
        "variance": "",
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    },
    {   
        "id": "21",
        "key": "Actual Payouts/Cash Out for the week",
        "name": "actual_payouts_or_cash_out_for_the_week",
        "description": "How much you paid out in cash this week.",
        "target": null,
        "actualValue": null,
        "variance": "",
        "isMinus": true,
        "lessThenTarget": 'vgreen',
        "moreThenTarget": 'vred',
        "redAndMinus": true,
        "isMirror": true
    },
    {
        "id": "22",
        "key": "TAX obligations up to date? Yes or No",
        "name": "tax_obligations_up_to_date_yes_or_no",
        "description": "Confirmation that our tax payments are paid or are you in a payment plan?",
        "target": null,
        "actualValue": null,
        "variance": "",
        "notarget": true
    },
    {
        "id": "23",
        "key": "Credit Limit",
        "name": "credit_limit",
        "description": "",
        "target": null,
        "actualValue": null,
        "variance": "",
        "notarget": true,
        "lessThenTarget": 'vred',
        "moreThenTarget": 'vgreen',
        "redAndMinus": true,
    }
]

const staticKeyNumbers = {
    "date": "",
    "balance_in_bank": 0.0,
    "debtors": 0.0,
    "creditors": 0.0,
    "work_in_progress": 0.0,
    "quotes_value_for_the_week": 0.0,
    "cost_of_goods_sold_for_the_week": 0.0,
    "total_employment_costs_for_the_week": 0.0,
    "revenue_for_the_week": 0.0,
    "number_of_invoices_for_the_week": 0.0,
    "average_invoice_for_the_week": 0.0,
    "quotes_value_for_the_month": 0.0,
    "cost_of_goods_sold_month_to_date": 0.0,
    "revenue_month_to_date": 0.0,
    "gross_profit_for_the_month_to_date": 0.0,
    "utilization_average_for_the_week": 0.0,
    "break_even_number_or_monthly": 0.0,
    "total_employment_costs_month_to_date": 0.0,
    "total_employment_costs_vs_revenue_ratio": 0.0,
    "liquidity_ratio": 0.0,
    "actual_banked_or_cash_in_for_the_week": 0.0,
    "actual_payouts_or_cash_out_for_the_week": 0.0,
    "tax_obligations_up_to_date_yes_or_no": "Yes",
    "credit_limit": 0.0,
    "created_by": 0
}

const staticQuotesRegister = [
    {
        row_id: 0,
        id: "",
        date_submitted: moment().format("YYYY-MM-DD"),
        job_id: "",
        client: "",
        description: "",
        address: "",
        post_code:"",
        quotation_ex_gst: "",
        status: "",
        quotation_win_date: moment().format("YYYY-MM-DD"),
        days_to_convert: "",
        comments: "",
        // isSave: true,
        isAdd: true,
        isValueAdded: false,
        isDisable: false
    }
]

const staticWorkInProgress = [
    {
        row_id: 0,
        id: "",
        date: moment().format("YYYY-MM-DD"),
        job_id: "",
        client: "",
        description: "",
        address: "",
        post_code:"",
        total_value_ex_gst: "",
        start_date: moment().format("YYYY-MM-DD"),
        invoiced_to_date_ex_gst: "",
        invoice_to_complete_ex_gst:"",
        completion: "",
        finish_date_est: moment().format("YYYY-MM-DD"),
        status: "",
        comments: "",
        // isSave: true,
        isAdd: true,
        isValueAdded: false,
        isDisable: false
    }
]

class login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            emailErrorMessage: "",
            passwordErrorMessage: "",
            emailRegex : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            errorMessage: ""
        }
    }

    login =  (e) => {
        e.preventDefault()
        if(!this.state.emailErrorMessage && !this.state.passwordErrorMessage && this.state.email !== "" && this.state.password !== ""){

      
        console.log('state---->',this.state,process.env,process.env.REACT_APP_BACKEND_URL) 

        // const history = useHistory()
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/login/`, { email: this.state.email,
            password: this.state.password })
            .then(async res => {
                
              console.log('res------------>',res)
            //   console.log('data-->',data)
                localStorage.setItem('email', this.state.email);
                localStorage.setItem('access', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                localStorage.setItem('user_id', res.data.id);
                localStorage.setItem('user_name',res.data.first_name +' '+ res.data.last_name)
                localStorage.setItem('expires_at',moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.sssZ'))
                localStorage.setItem('defalutQuotesRegister', JSON.stringify(staticQuotesRegister))
                localStorage.setItem('defalutWorkInProgress', JSON.stringify(staticWorkInProgress))
                
            //   this.setLocalStorage(res);
              await this.getDescription()


            // history.push("/")
            }).catch((err) => {
                this.setState({
                    errorMessage: 'No active account found with the given credentials.'
                }) 
                console.log('err->',err)
             
            })


        }else if(this.state.email == "") {
            this.setState({
                emailErrorMessage: "Please enter email address."
            }) 
        }else if(this.state.password == "") {
            this.setState({
                passwordErrorMessage: "Please enter valid password."
            }) 
        }

    }

    getDescription = async () => {
        var config = {
                method: 'get',
                headers: { 
                    'Authorization': 'Bearer '+localStorage.getItem('access')
                },
                url: `${process.env.REACT_APP_BACKEND_URL}/description/`
            };

        await axios(config)
        .then( async (response) => {
            console.log('response description----->',response)
            var descriptions = response.data
            for(let x in descriptions){
                    
                await staticValue.map((prod,i) => {
                    // console.log('prod-->',prod,i)
                    if(prod.name == x){
                        prod.description1 = descriptions[x]
                    }
                })
            }
            localStorage.setItem('defaultKeyNumbers', JSON.stringify(staticValue))
            localStorage.setItem('onlyKeyNumbers', JSON.stringify(staticKeyNumbers))
            var url = process.env.REACT_APP_FRONTEND_URL+'/dashboard'
            console.log('afeter added ------>',staticValue,process.env.REACT_APP_FRONTEND_URL,url)

            window.location.replace(url);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    setLocalStorage = (data) => {
        console.log('data-->',data)
        localStorage.setItem('email', this.state.email);
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        localStorage.setItem('user_id', data._id);
    }

    onClickEmail = (e) => {
        console.log('e.target.value-->',e.target.value,e.target.value.length)
        if(e.target.value == ""){
            this.setState({
                email: e.target.value,
                errorMessage: "",
                emailErrorMessage: "Please enter email address."
            }) 
        }else if(this.state.emailRegex.test(e.target.value) === false){
            this.setState({
                email: e.target.value,
                errorMessage: "",
                emailErrorMessage: "Please enter valid email address."
            }) 
        }else {
            this.setState({
                email: e.target.value,
                errorMessage: "",
                emailErrorMessage: ""
            })
        }

    }

    onClickPassword = (e) => {
        if(e.target.value == ""){
            this.setState({
                password: e.target.value,
                errorMessage: "",
                passwordErrorMessage: "Please enter valid password."
            }) 
        }else{
            this.setState({
                password: e.target.value,
                errorMessage: "",
                passwordErrorMessage: ""
            })
        }
    }
    
    render() {
        console.log('dashboard page->')
        console.log('state---->',this.state)
        const { emailErrorMessage, passwordErrorMessage, errorMessage } = this.state
        return (
            <React.Fragment>
                <div className="page">
        
                    {/* <!-- header section Start --> */}
                    <header className="sticky-header">
                        <div className="header-nav">
                            <nav className="navbar navbar-expand-lg">
                                <div className="container">
                                    <div className="site-logo">
                                        <a href="#">
                                            <img src="assets/images/logo.png"></img>
                                        </a>
                                    </div>
                                    <button class="navbar-toggler toggler_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar_toggler_icon navbar_toggler_icon_top"></span>
                                        <span class="navbar_toggler_icon navbar_toggler_icon_middel"></span>
                                        <span class="navbar_toggler_icon navbar_toggler_icon_bottom"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarToggler">
                                        <div className="navbar-close">
                                            <div className="cross-wrap"><span></span><span></span></div>
                                        </div>
                                        <ul className="navbar-nav ms-auto">
                                            <li className="nav-item">
                                                <Link to='/' className="nav-link">HOME</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to='/contactus' className="nav-link">CONTACT US</Link>
                                            </li>
        
                                            <li className="nav-item">
                                                <Link to='/' className="theme_btn">SIGN IN</Link>
                                            </li>
        
                                        </ul>
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                            <span></span><span></span><span></span>
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </header>
                    {/* <!-- header section End --> */}
        
                    <div className="inner_container">
                        <div className="container p-0">
                            <div className="login_page login_display">
                                <div className="login_left form_sec">
                                    <div className="w-100">
                                        <h1>Welcome back</h1>
                                        <p>Welcome back! please enter your details</p>
                                        <form onSubmit={this.login}>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" name="" className="form-control" placeholder="Enter your email" value={this.state.email} onChange={(e) => this.onClickEmail(e)}></input>
                                                {emailErrorMessage && (<p className="error"> {emailErrorMessage} </p>)}
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input type="password" name="" className="form-control" placeholder="Enter your password" value={this.state.password} onChange={(e) => this.onClickPassword(e)}></input>
                                                {passwordErrorMessage && (<p className="error"> {passwordErrorMessage} </p>)}
                                            </div>
        
                                            <div className="form-check w-auto">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                                                <label className="form-check-label" for="flexCheckDefault">
                                                Remember me
                                            </label>
                                            </div>
                                            <Link to="/forgetpassword" className="forg_psw">Forgot Password ?</Link>
                                            {errorMessage && (<p className="error"> {errorMessage} </p>)}
                                            <div className="auth_btn">
                                                <button className="theme_btn">SIGN IN</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="login_right bg-img-c">
                                    <div className="slider_section">
                                        <div className="owl-carousel owl-theme testimonial_slider">
                                            <div className="item testimonial-item">
                                                <div className="content">
                                                    <p>"We've been using Business Benchmark Group to kick start every new project and can't imagine working without it."</p>
                                                </div>
                                                <div className="author">
                                                    <h4>Andi Lane</h4>
                                                    <span>Founder, Telemarketer</span>
                                                </div>
                                            </div>
                                            <div className="item testimonial-item">
                                                <div className="content">
                                                    <p>"We've been using Business Benchmark Group to kick start every new project and can't imagine working without it."</p>
                                                </div>
                                                <div className="author">
                                                    <h4>Andi Lane</h4>
                                                    <span>Founder, Telemarketer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <div className="container">
                            <div className="footer_inner">
                                <span>Copyright © 2022 Business Benchmark Group, All rights reserved.</span>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/metro-facebook.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/awesome-twitter.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/awesome-youtube.svg"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="assets/images/awesome-linkedin.svg"></img>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </footer>
        
                   
                    
                </div>
            </React.Fragment>
          )
    }
}
export default login;
